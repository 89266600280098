import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../../../_services/toastService";

function RemoveModal({ data = {}, hideModal, removeAssignment }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      removeAssignment();
      toastService.send({ title: "Verwijderd", message: "Taaktoewijzing is verwijderd" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Taaktoewijzing verwijderen?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Ben je zeker dat je de taaktoewijzing van <span className="font-bold">{data.collega.firstName} {data.collega.lastName}</span> voor <span className="font-bold">{data.task.name}</span> wilt verwijderen?</Form.Label>
              </Form.Group>

              <Button variant="danger" type="submit" disabled={snapshot}>
                <span>
                  <i className="far fa-trash" />
                  &nbsp;Verwijder
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default RemoveModal;
