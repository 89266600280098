/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Table } from "react-bootstrap";
import KanbanTask from "./KanbanTask";

const KanbanColumn = props => (
  <div style={{ border: `5px solid ${props.columnData.color}` }} className={props.columnData.show ? "KanbanColumn" : "KanbanColumn d-none"}>
    <div className="KanbanColumn-main">
      <div className="sticky-top bg-white">
        <h2 className="KanbanColumn-name text-truncate">{props.columnData.name}</h2>
        <p className="KanbanColumn-numbers">
          {props.columnData.currentVTE} % / {props.columnData.percentageAvailableOverall} %
        </p>

        {props.columnData.filteredProgrammesLength !== 0 && (
          <p className="KanbanColumn-numbers font-bold">
            <span className={(props.columnData.filteredVTE < props.columnData.percentageAvailableFiltered - 2.5 || props.columnData.filteredVTE > props.columnData.percentageAvailableFiltered + 2.5) && "text-danger"}>{props.columnData.filteredVTE} %</span> / {props.columnData.percentageAvailableFiltered} %
          </p>
        )}
        <Table striped bordered className="KanbanColumn-numbers KanbanColumn-contactHours">
          <tbody>
            <tr>
              <td>
                {props.columnData.contactHoursQ1 > props.columnData.contactHoursLimit ? (
                  <strong className="text-danger">{props.columnData.contactHoursQ1}</strong>
                ) : (
                  props.columnData.contactHoursQ1
                )}
              </td>
              <td>
                {props.columnData.contactHoursQ2 > props.columnData.contactHoursLimit ? (
                  <strong className="text-danger">{props.columnData.contactHoursQ2}</strong>
                ) : (
                  props.columnData.contactHoursQ2
                )}
              </td>
              <td>
                {props.columnData.contactHoursQ3 > props.columnData.contactHoursLimit ? (
                  <strong className="text-danger">{props.columnData.contactHoursQ3}</strong>
                ) : (
                  props.columnData.contactHoursQ3
                )}
              </td>
              <td>
                {props.columnData.contactHoursQ4 > props.columnData.contactHoursLimit ? (
                  <strong className="text-danger">{props.columnData.contactHoursQ4}</strong>
                ) : (
                  props.columnData.contactHoursQ4
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Droppable droppableId={`${props.columnData.collegaID}`}>
        {provided => (
          <div
            ref={provided.innerRef}
            className="KanbanColumns-tasks-container"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}>
            {props.columnData.taskIds.map(
              (task, index) =>
                task && (
                  <KanbanTask
                    key={task.id}
                    id={task.id}
                    task={task}
                    color={props.columnData.color}
                    index={index}
                    removeTask={props.removeTask}
                    editTask={props.editTask}
                  />
                ),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  </div>
);

export default KanbanColumn;
