import { DefaultApi, DefaultApiFetchParamCreator } from "./api/src";
import { API_URL } from "../Config";

const localFetch = async (url, options) => {
  console.log(url, options);
  options.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return fetch(url, options).then(res => {
    if (res.status === 401) {
      localStorage.removeItem("token");
    }

    return res;
  });
};

// this exporter is only used if you need to have raw fetch() access!
// otherwise you can use the default exporter

export const getApiURL = () => API_URL;
export const getFetchParamGenerator = () => {
  const fetchParamCreator = new DefaultApiFetchParamCreator({ basePath: API_URL });
  return { fetchParamCreator, fetch: localFetch };
};

export default () => {
  // inject our own fetch as the openapi generator doesn't support Bearer auth
  const api = new DefaultApi({ basePath: API_URL }, localFetch);

  return api;
};
