/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import Dialog from "@maartje/react-bootstrap-dialog";
import Dropzone from "react-dropzone";
import { deleteAllAssignmentsForGraduates, importGraduatesFromExcel } from "../../_helpers/graduatesimport";

function Graduates() {
  const [dialog, setDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);

  const onFormSubmit = e => {
    e.preventDefault();
    dialog.show({
      title: "Ben je zeker?",
      body: `Ben je echt zeker dat je alle taak- en vaktoewijzingen voor de graduaten wilt verwijderen? Weg is weg...`,
      actions: [
        Dialog.Action("Annuleren", d => d.hide(), "btn-outline-secondary"),
        Dialog.Action(
          <span>
            <i className="fas fa-trash" />
            &nbsp;Verwijderen
          </span>,
          async () => {
            setLoading(true);
            await deleteAllAssignmentsForGraduates();
            setLoading(false);
          },
          "btn-danger",
        ),
      ],
      bsSize: "small",
      onHide: d => d.hide(),
    });
  };

  const onDrop = acceptedFiles => {
    setUploading(true);
    acceptedFiles.forEach(file => {

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        importGraduatesFromExcel(reader.result).then((result) => {
          setUploading(false);
          setErrors(result.errors);
          setSuccess(result.success);
        }
        );
      }
      reader.readAsBinaryString(file);
    });
  };

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      <Dialog ref={component => setDialog(component)} />
      <Row>

        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Graduaten (AJ 2023-2024)</h1>

          <Form onSubmit={onFormSubmit}>
            <Button variant="primary" type="submit">
              <span className="fw-bold">Alle vak- en taaktoewijzingen voor graduaten verwijderen</span>
              <img className="col-md-5 home-image" src="\BigRedButton.jpg" alt="Big red button to delete all graduates assignments" />
            </Button>
          </Form>
          {uploading ? (
            <div>
              <i className="fas fa-cog fa-spin mt-5" /> Importeren...
            </div>
          ) : (
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section className="dropzone mt-5">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Sleep het Excel-bestand in dit vak, of klik om een bestand te kiezen</p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}
          {success && (
            <p style={{ color: "green" }}>{success}</p>
          )}
          {errors && (
            <>{errors.map((error, index) => (
              <p style={{ color: "red" }} data-index={index}>{error}</p>
            ))}</>
          )}
        </div>
      </Row>
    </Container>
  );
}

export default Graduates;
