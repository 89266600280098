import { createSlice } from "@reduxjs/toolkit";

export const snapshotSlice = createSlice({
  name: "snapshot",
  initialState: {
    fileName: "",
  },
  reducers: {
    live: state => {
      state.fileName = "";
    },
    goBack: (state, data) => {
      state.fileName = data.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { live, goBack } = snapshotSlice.actions;

export default snapshotSlice.reducer;
