import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { isLoggedIn, getRedirectURL, login } from "./_services/oidc";
import AppNav from "./Nav";
import Home from "./pages/home/Home";
import Collegas from "./pages/collegas/Collegas";
import Programmes from "./pages/programmes/Programmes";
import OPOs from "./pages/opos/OPOs";
import Phases from "./pages/phases/Phases";
import Parameters from "./pages/parameters/Parameters";
import GlobalToast from "./pages/_components/GlobalToast";
import OLAAssignment from "./pages/assignment/olas/OLAs";
import TaskAssignment from "./pages/assignment/tasks/tasks";
import Tasks from "./pages/tasks/Tasks";
import PersonalSetup from "./pages/personalsetup/personalSetup";
import PerformanceScheme from "./pages/performancescheme/performanceScheme";
import Graduates from "./pages/graduates/Graduates";
import TaskCategories from "./pages/taskcategories/TaskCategories";
import store from "./redux/store";
import "./scss/App.scss";
import RulesCheck from "./pages/rules/Check";
import PersonalView from "./pages/assignment/Kanban/PersonalView";
import ManagePerfomancescheme from "./pages/manage-performancescheme/ManagePerfomancescheme";
import Parallels from "./pages/parallels/Parallels";

function App() {
  if (window.location.pathname.indexOf("/oidc/callback") === 0) {
    const params = new URLSearchParams(window.location.search);
    login(params.get("code")).then(to => {
      window.location.href = to;
    });
    return <div>Even geduld...</div>;
  }
  if (!isLoggedIn()) {
    getRedirectURL().then(url => {
      window.location.href = url;
    });

    return <div>Even geduld... U wordt zodadelijk geauthenticeerd</div>;
  }
  return (
    <Provider store={store}>
      <Router>
        <AppNav />
        <Container className="content-page">
          <GlobalToast />
          <Switch>
            <Route path="/collegas/:id">
              <Collegas />
            </Route>
            <Route path="/collegas">
              <Collegas />
            </Route>
            <Route path="/programmes">
              <Programmes />
            </Route>
            <Route path="/Phases">
              <Phases />
            </Route>
            <Route path="/opos">
              <OPOs />
            </Route>
            <Route path="/parameters">
              <Parameters />
            </Route>
            <Route path="/toewijzing/olas">
              <OLAAssignment />
            </Route>
            <Route path="/toewijzing/teachers">
              <PersonalView />
            </Route>
            <Route path="/toewijzing/tasks">
              <TaskAssignment />
            </Route>
            <Route path="/rules/check">
              <RulesCheck />
            </Route>
            <Route path="/tasks">
              <Tasks />
            </Route>
            <Route path="/personalsetup">
              <PersonalSetup />
            </Route>
            <Route path="/myperformancescheme">
              <PerformanceScheme />
            </Route>
            <Route path="/manage-performancescheme">
              <ManagePerfomancescheme />
            </Route>
            <Route path="/graduates">
              <Graduates />
            </Route>
            <Route path="/taskcategories">
              <TaskCategories />
            </Route>
            <Route path="/parallels">
              <Parallels />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </Router>
    </Provider>
  );
}

export default App;
