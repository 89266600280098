import { useEffect, useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import '@inovua/reactdatagrid-community/index.css'
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";

import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import { calculateRoundedProgrammeVTEPreFetched, calculateAvailableFTEPreFetched, loadParameters } from "../../_helpers/calculations";
import { downloadBlob } from "../../_helpers/excel";

const api = SatAPI();

function Programmes() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [programmes, setProgrammes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);
  const [unitPlannedFTE, setUnitPlannedFTE] = useState(0);
  const [unitBudgetFTE, setUnitBudgetFTE] = useState(0);
  const [unitAvailableFTE, setUnitAvailableFTE] = useState(0);
  const [gridRef, setGridRef] = useState(null);

  const loadProgrammes = async () => {
    try {
      setLoading(true);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name ? 1 : -1);
      setProgrammes(response);

      const collegas = await api.getCollegas(snapshot);
      const assignments = await api.getAssignments(snapshot);
      const taskAssignments = await api.getTaskAssignments(snapshot);
      const { general, cs, ects } = await loadParameters();

      const oposResponse = await api.getOPOs(snapshot);
      oposResponse.sort((a, b) => b.name < a.name ? 1 : -1);
      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }

      const allOLAsFromOPOS = [];
      oposResponse.forEach(opo => {
        opo.olas.forEach(ola => {
          allOLAsFromOPOS.push(ola);
        });
      });

      // TODO: improve this in new back-end!
      // Overwrite the OLA's in parallel because they don't contain OLA phases,
      // which is necessary for calculation of student numbers
      currentParallels.forEach(parallel => {
        parallel.mainOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.mainOLAID);
        parallel.subOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.subOLAID);
      });

      let unitPlanned = 0;
      let unitBudget = 0;
      let unitAvailable = 0;
      response.forEach(programme => {
        programme.fte = calculateRoundedProgrammeVTEPreFetched(collegas, assignments, taskAssignments, programme, currentParallels, general, cs, ects);
        programme.availableFTE = calculateAvailableFTEPreFetched(collegas, programme);
        unitPlanned += programme.fte;
        unitBudget += programme.budgetFTE;
        unitAvailable += programme.availableFTE;
      });
      setUnitPlannedFTE(unitPlanned);
      setUnitBudgetFTE(unitBudget);
      setUnitAvailableFTE(unitAvailable);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: `${error}` });
    }
  };

  const openEditModal = programme => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadProgrammes();
        }}
        data={programme}
      />,
    );
  };

  const openRemoveModal = programme => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadProgrammes();
        }}
        data={programme}
      />,
    );
  };

  const gridStyle = { minHeight: 550 }

  const initialColumns = [
    { name: 'name', header: 'Naam', defaultFlex: 1 },
    { name: 'forfaitFactor', header: 'Factor forfait', type: 'number', defaultFlex: 1, render: ({ value }) => `100% = ${value} uur`, filterEditor: NumberFilter },
    { name: 'budgetFTE', header: 'Budget VTE', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    { name: 'availableFTE', header: 'Beschikbaar VTE', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    { name: 'fte', header: 'Totaal geplande VTE', type: 'number', defaultFlex: 1, render: ({ value }) => value.toFixed(4), filterEditor: NumberFilter },
    {
      name: 'ID', header: 'Acties', defaultFlex: 1, draggable: false, sortable: false, render: ({ data }) => <div>
        <Button variant="info" onClick={() => openEditModal(data)} disabled={snapshot}>
          <i className="far fa-edit" />
        </Button>
        <Button variant="danger" onClick={() => openRemoveModal(data)} disabled={snapshot}>
          <i className="far fa-trash" />
        </Button></div>
    }
  ];

  const [columns] = useState(initialColumns);

  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'forfaitFactor', operator: 'inrange', type: 'number', value: 0 },
    { name: 'budgetFTE', operator: 'inrange', type: 'number', value: 0 },
    { name: 'availableFTE', operator: 'inrange', type: 'number', value: 0 },
    { name: 'fte', operator: 'inrange', type: 'number', value: 0 }
  ];

  const exportCSV = () => {
    const { visibleColumns } = gridRef.current;

    const header = visibleColumns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => visibleColumns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob, 'opleidingen.csv');
  };

  useEffect(() => {
    loadProgrammes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Opleidingen</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportCSV()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>{" "}
          <Button variant="success" onClick={() => openEditModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactDataGrid
            handle={setGridRef}
            idProperty="ID"
            style={gridStyle}
            defaultFilterValue={filterValue}
            columns={columns}
            dataSource={programmes}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <h4>Totaal alle opleidingen: </h4>
        </Col>
        <Col xs={3}>
          <h4>Budget: {unitBudgetFTE.toFixed(2)} VTE</h4>
        </Col>
        <Col xs={3}>
          <h4>Beschikbaar: {unitAvailableFTE.toFixed(2)} VTE</h4>
        </Col>
        <Col xs={3}>
          <h4>Gepland: {unitPlannedFTE.toFixed(2)} VTE</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default Programmes;
