/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import useToggle from "./useToggleState";
import KanbanEditForm from "./KanbanEditForm";

const KanbanTask = (props) => {
    const [isEditing, toggle] = useToggle(false);

    return (
        <Draggable draggableId={`${props.task.id}`} index={props.index}>
            {(provided) => (
                <div
                    style={{ backgroundColor: (props.task.color || '#e6e6e6') }}
                    className={props.task.show ? "KanbanTask" : "KanbanTask d-none"}>
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        {isEditing ? (
                            <KanbanEditForm
                                color={props.color}
                                editTask={props.editTask}
                                taskId={props.task.id}
                                toggle={toggle}
                                startUser={props.task.user}
                                startText={props.task.text}
                            />
                        ) : (
                            <>
                                <div className="KanbanTask-assigned">
                                    <span className="KanbanTask-assigned-img">
                                        {props.task.type === "task" && (
                                            <span>
                                                <i className="fas fa-thumbtack" />
                                            </span>
                                        )}
                                        {props.task.opoResponsible && (
                                            <span>
                                                <i className="far fa-award" />
                                            </span>
                                        )} {props.task.ola} {props.task.internship && (
                                            <span>({props.task.internshipStudents})</span>
                                        )}
                                        {props.task.percentageOverride > 0 && (
                                            <span className="text-warning">
                                                &nbsp;<i className="fas fa-bolt" />
                                            </span>
                                        )}
                                        {props.task.comment && (
                                            <span className="text-info">
                                                &nbsp;<i className="far fa-comment-dots" />
                                            </span>
                                        )}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default KanbanTask;