import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function EditModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [mainOLAID, setMainOLAID] = useState(data.mainOLAID || 0);
  const [subOLAID, setSubOLAID] = useState(data.subOLAID || 0);
  const [level, setLevel] = useState(data.level || 1);
  const [olas, setOLAs] = useState([]);

  useEffect(() => {
    api.getOPOs(snapshot).then(
      async () => {
        const opos = await api.getOPOs(snapshot);
        opos.sort((a, b) => b.name < a.name ? 1 : -1);

        const allOLAs = [];
        opos.forEach(opo => {
          opo.olas.forEach(ola => {
            allOLAs.push(ola);
          });
        });

        allOLAs.sort((a, b) => b.name < a.name ? 1 : -1);

        setOLAs(allOLAs);
      },
      error => toastService.send({ title: "An error occured", message: error }),
    );
  }, [snapshot]);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      if (data.ID) {
        await api.updateParallel(data.ID, {
          mainOLAID,
          subOLAID,
          level,
        });
      } else {
        await api.createParallel({ mainOLAID, subOLAID, level });
      }

      api.createSnapshot();
      toastService.send({ title: "Success", message: "Parallel is opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Parallel {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Hoofdvak</Form.Label>
                <Form.Select onChange={e => setMainOLAID(parseInt(e.target.value, 10))} value={mainOLAID}>
                  <option value={null}>Selecteer een OLA</option>
                  {olas.map(ola => (
                    <option key={ola.ID} value={ola.ID} selected={ola.ID === mainOLAID}>
                      {ola.name} ({ola.zCode})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Parallelvak</Form.Label>
                <Form.Select onChange={e => setSubOLAID(parseInt(e.target.value, 10))} value={subOLAID}>
                  <option value={null}>Selecteer een OLA</option>
                  {olas.map(ola => (
                    <option key={ola.ID} value={ola.ID} selected={ola.ID === subOLAID}>
                      {ola.name} ({ola.zCode})
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Niveau</Form.Label>
                <Form.Control
                  type="number"
                  required
                  step="1"
                  min="1"
                  onChange={e => setLevel(parseFloat(e.target.value))}
                  value={level}
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" />
                    &nbsp;Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" />
                    &nbsp;Aanmaken{" "}
                  </span>
                )}
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
