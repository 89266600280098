/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Container, Form, Col, Button } from "react-bootstrap";
import ChangeSourceOfTruthModal from "./ChangeSourceOfTruthModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function ManagePerfomancescheme() {
  const [snapshots, setListSnapshots] = useState([]);
  const [truthSource, setTruthSource] = useState({});

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(null);

  const loadSnapshots = async () => {
    try {
      setLoading(true);

      setListSnapshots(await api.getSnapshots());
      setTruthSource(await api.getSourceOfTruthSnapshot());

      setLoading(false);
    } catch (error) {
      if (typeof error.json === "function") {
        error.json().then(jsonError => {
          toastService.send({ title: "Error", message: jsonError.message });
          console.log(jsonError);
        });
      } else {
        toastService.send({ title: "Error", message: "Error bij verbinden naar server" });
      }
    }
  };

  const openModal = data => {
    setModal(
      <ChangeSourceOfTruthModal
        hideModal={() => {
          setModal(null);
          loadSnapshots();
        }}
        data={data}
      />,
    );
  };

  useEffect(() => {
    loadSnapshots();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {modal}
      <Row>
        <h1>Prestatieregeling beheer</h1>
      </Row>
      <Row>
        <h2>Bron gegevens &quot;Mijn prestatieregeling&quot;</h2>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Snapshot</Form.Label>
              <Form.Select onChange={e => setTruthSource(snapshots.filter(s => s.fileName === e.target.value)[0])} value={truthSource.fileName}>
                <option value={null}>Selecteer een snapshot</option>
                {snapshots.map(p => (
                  <option key={p.fileName} value={p.fileName}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Button variant="success" type="button" onClick={() => openModal(truthSource)}>
              <i className="far fa-save" /> Opslaan
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ManagePerfomancescheme;
