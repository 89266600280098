/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Table, Container, Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function Tasks() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [tasks, setTasks] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);

  // filter
  const [selectedProgrammeIDs, setSelectedProgrammeIDs] = useState([]);

  const loadTasks = async () => {
    try {
      setLoading(true);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name);
      setProgrammes(response);

      const taskResponse = await api.getTasks(snapshot);
      taskResponse.sort((a, b) => b.name < a.name);
      setTasks(taskResponse);

      // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
      if (!localStorage.getItem('SAT-PersonalProgrammeFilter')) {
        localStorage.setItem('SAT-PersonalProgrammeFilter', JSON.stringify([]));
      }

      let personalProgrammeIDs = [];
      JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter')).forEach(selectedProgramme => {
        personalProgrammeIDs = [...personalProgrammeIDs, selectedProgramme.ID];
      });
      setSelectedProgrammeIDs(personalProgrammeIDs);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  const openEditModal = data => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadTasks();
        }}
        data={data}
      />,
    );
  };

  const openRemoveModal = task => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadTasks();
        }}
        data={task}
      />,
    );
  };

  useEffect(() => {
    loadTasks();
  }, [snapshot]);

  const toggleSelectedProgrammeFiler = (id, checked) => {
    if (checked) {
      setSelectedProgrammeIDs([...selectedProgrammeIDs, id]);
    } else {
      setSelectedProgrammeIDs(selectedProgrammeIDs.filter(i => i !== id));
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Taken</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="success" onClick={() => openEditModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <h3>Filter</h3>
          <hr />
          <h4>Opleiding</h4>
          <ul className="list-group">
            {programmes.map(programme => (
              <li key={programme.ID} className="list-group-item">
                <Form.Check
                  inline
                  label={programme.name}
                  id={programme.ID}
                  type="checkbox"
                  checked={selectedProgrammeIDs.includes(programme.ID)}
                  onChange={e => toggleSelectedProgrammeFiler(programme.ID, e.target.checked)}
                />
              </li>
            ))}
          </ul>
        </Col>
        <Col md={10}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Percentage</th>
                <th>Categorie</th>
                <th>Opleidingen</th>
                <th className="text-transparent">&nbsp;.</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map(c => (
                <tr
                  key={c.id}
                  className={c.programmes.filter(p => selectedProgrammeIDs.includes(p.ID)).length > 0 || selectedProgrammeIDs.length === 0 ? "" : "d-none"}>
                  <td>
                    {c.name}&nbsp;
                    {c.comment !== "" && (
                      <span className="text-info">
                        <i className="far fa-comment-dots" />
                      </span>
                    )}
                  </td>
                  <td>{c.percentage}</td>
                  <td>{c.category.name}</td>
                  <td>
                    <ul>
                      {c.programmes.map(programme => (
                        <li key={programme.ID}>{programme.name}</li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-end">
                    <Button variant="info" onClick={() => openEditModal(c)}>
                      <i className="far fa-edit" />
                    </Button>{" "}
                    <Button variant="danger" onClick={() => openRemoveModal(c)} disabled={snapshot}>
                      <i className="far fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Tasks;
