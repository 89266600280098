/* eslint-disable react/jsx-props-no-spreading */
import { useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import SatAPI from "../../_services/sat";
import toastService from "../../_services/toastService";

const api = SatAPI();

function EditModal({ hideModal }) {
  const [uploading, setUploading] = useState(false);

  const onDrop = acceptedFiles => {
    setUploading(true);
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        api
          .importOPOs(reader.result)
          .then(() => {
            setUploading(false);
            hideModal();
          })
          .catch(error => {
            setUploading(false);
            hideModal();
            if (typeof error.json === "function") {
              error.json().then(jsonError => {
                toastService.send({ title: "Error", message: jsonError.message });
                console.log(jsonError);
              });
            } else {
              toastService.send({ title: "Error", message: "Error bij verbinden naar server" });
            }
          });
      };
      reader.readAsDataURL(file);
    });
  };

  const downloadExcel = () => {
    window.open("/import-opo.xlsx", "_blank");
  };

  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>OPO&apos;s importeren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <p>
              Je staat op het punt om OPO&apos;s te importeren vanuit Excel naar SAT. Zorg er voor dat je het aangeleverde sjabloon gebruikt (knop &apos;Download Excel&apos; onderaan). Zorg er ook voor dat je
              enkel OPO&apos;s importeert die nog niet in SAT staan om dubbels te vermijden.
            </p>
            <p>Ben je er klaar voor?</p>
          </Col>
          <Col xs={12}>
            {uploading ? (
              <div>
                <i className="fas fa-cog fa-spin" /> Importeren...
              </div>
            ) : (
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Sleep het Excel-bestand in dit vak, of klik om een bestand te kiezen</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </Col>
        </Row>
        <Modal.Footer>
          <Button onClick={downloadExcel} variant="secondary">
            <i className="fas fa-download" />
            &nbsp; Download Excel
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
