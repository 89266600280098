import { useEffect, useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";

const possibleSources = ["Werkingsmiddelen", "Ziektereserve", "STUVO", "Extern - Cello", "Extern - Projecten", "Voorsprongfonds", "TBSOB", "Andere"];

function EditFinanceSource({ data = {}, remove, saveData }) {
  const [name, setName] = useState(data.name || possibleSources[0]);
  const [percentage, setPercentage] = useState(data.percentage || 100);
  const [comments, setComments] = useState(data.comments || "");

  useEffect(() => {
    data.name = name;
    data.percentage = percentage;
    data.comments = comments;
    saveData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, percentage, comments]);

  return (
    <Row className="border m-3 p-2">
      <Form>
        <Row className="mb-3">
          <Col xs={5}>
            <Form.Group className="mb-3">
              <Form.Label>Financieringsbron</Form.Label>
              <Form.Select onChange={e => setName(e.target.value)} value={name}>
                {possibleSources.map(p => (
                  <option key={p} value={p} selected={name === p}>
                    {p}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={5}>
            <Form.Group className="mb-3">
              <Form.Label>Percentage</Form.Label>
              <Form.Control type="number" min="0" max="100" required onChange={e => setPercentage(parseFloat(e.target.value, 10))} value={percentage} />
            </Form.Group>
          </Col>
          <Col xs={1}>
            <Form.Label>&nbsp;</Form.Label>
            <Button variant="danger" onClick={() => remove()}>
              <i className="far fa-trash" />
            </Button>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Opmerking</Form.Label>
              <Form.Control type="text" onChange={e => setComments(e.target.value)} value={comments} />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default EditFinanceSource;
