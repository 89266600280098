import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function ChangeSourceOfTruthModal({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      if (data) {
        await api.updateSourceOfTruthSnapshot(data);
      }
      toastService.send({ title: "Success", message: "De bron van de ultieme waarheid is gewijzigd" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ben je zeker?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Ben je echt zeker dat je <span className="font-bold">{data.name}</span> de bron van de ultieme waarheid der prestatieregelingen wil maken?</Form.Label>
              </Form.Group>

              <Button variant="danger" type="submit" disabled={snapshot}>
                <span>
                  <i className="fas fa-magic" />
                  &nbsp;Pas aan!
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ChangeSourceOfTruthModal;
