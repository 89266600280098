/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Table, Container, Col, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import sortBy from "lodash.sortby";
import SatAPI from "../../../_services/sat";
import toastService from "../../../_services/toastService";
import DocentList from "./components/DocentList";
import AssignmentEdit from "./components/AssignmentEdit";
import VTESimulationModal from "./components/VTESimulationModal";
import StageOLA from "./components/StageOLA";
import { calculateRoundedOLAVTEPreFetched, loadParameters, calculateRoundedCollegaVTEPreFetched } from "../../../_helpers/calculations";
import { getCircularReplacer } from "../../../_helpers/json";

const api = SatAPI();
function OLAAssignment() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [olas, setOLAsInt] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [phases, setPhases] = useState([]);
  const [parallels, setParallels] = useState([]);
  const [allAssignments, setAllAssignments] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState(null);
  const [VTEModal, setVTEModal] = useState(null);
  const [hasChanges, setHasChanges] = useState({});
  const [saving, setSaving] = useState(false);
  const [deletedAssignments, setDeletedAssignments] = useState([]);
  const [parameters, setParameters] = useState({});

  // calculated
  const [tags, setTags] = useState({});
  const [phaseIDs, setPhaseIDs] = useState([]);

  // filters
  const [selectedProgrammeIDs, setSelectedProgrammeIDs] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedPhaseIDs, setSelectedPhaseIDs] = useState([]);

  const history = useHistory();

  // custom hooks
  const setOLAs = (o, params) => {
    setOLAsInt(
      o.map(ola => {
        if (!parameters.general && !params) {
          return ola; // no parameters loaded do not edit it
        }
        if (!parameters.general && !params) {
          return ola; // no parameters loaded and not passed any do not edit it
        }
        if (parameters.general) {
          // if parameters are loaded use those
          params = parameters;
        }
        ola.assignments.map(assignment => {
          assignment.collegaVTE = calculateRoundedCollegaVTEPreFetched(assignment.collega, ola.assignments, [], parallels, allAssignments, params.general, params.cs, params.ects, false);

          assignment.numberOfAssignments = ola.assignments.filter(a => a.collegaID === assignment.collegaID).length;
          console.log(assignment.numberOfAssignments);
          return assignment;
        });

        ola.vte = calculateRoundedOLAVTEPreFetched(ola, ola.assignments, ola.phases, parallels, allAssignments, params.general, params.cs, params.ects);
        return ola;
      }),
    );
  };

  // recalc percentages when parameters change or just get loaded
  useEffect(() => {
    setOLAs(olas);
  }, [parameters]);

  const markAsChanged = id => {
    setHasChanges({ ...hasChanges, [id]: true });
  };

  const runFilter = (newOLAs, setOLAParams) => {
    // filter OLAs
    setOLAs(
      (newOLAs || olas).map(ola => {
        ola.show = true; // default

        // filter by programme
        if (selectedProgrammeIDs.length > 0 && !selectedProgrammeIDs.includes(ola.opo.programmeID)) {
          ola.show = false;
          return ola;
        }

        // filter by tags
        if (selectedTags.length > 0) {
          ola.show = false;
          ola.opo.tags.forEach(tag => {
            if (selectedTags.includes(tag.name)) {
              ola.show = true;
            }
          });
        }

        // filter by phase
        if (selectedPhaseIDs.length > 0) {
          ola.show = false;
          ola.phases.forEach(phase => {
            if (selectedPhaseIDs.includes(phase.phaseID)) {
              ola.show = true;
            }
          });
        }

        return ola;
      }, setOLAParams),
    );
  };

  useEffect(runFilter, [selectedProgrammeIDs, selectedTags, selectedPhaseIDs]);

  const load = async (setLoad = true) => {
    try {
      setLoading(setLoad);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name);
      setProgrammes(response);

      const phasesResponse = await api.getPhases(0, snapshot);
      phasesResponse.sort((a, b) => b.name < a.name);
      setPhases(phasesResponse);

      const oposResponse = await api.getOPOs(snapshot);
      oposResponse.sort((a, b) => b.name < a.name ? 1 : -1);
      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }

      const allOLAsFromOPOS = [];
      oposResponse.forEach(opo => {
        opo.olas.forEach(ola => {
          allOLAsFromOPOS.push(ola);
        });
      });

      // TODO: improve this in new back-end!
      // Overwrite the OLA's in parallel because they don't contain OLA phases,
      // which is necessary for calculation of student numbers
      currentParallels.forEach(parallel => {
        parallel.mainOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.mainOLAID);
        parallel.subOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.subOLAID);
      });

      setParallels(currentParallels);

      const { general, cs, ects } = await loadParameters();
      setParameters({ general, cs, ects });

      const assignments = await api.getAssignments(snapshot);
      setAllAssignments(assignments); // for calculating parallels

      const opoResponse = await api.getOPOs(snapshot);
      opoResponse.sort((a, b) => a.programme.name.localeCompare(b.programme.name) || a.name.localeCompare(b.name));
      const allOLAs = [];
      opoResponse.forEach(opo => {
        opo.olas.forEach(ola => {
          ola.opo = opo;
          ola.classes = 0;
          ola.assignments = assignments.filter(a => a.olaID === ola.ID);
          ola.assignments.sort((a, b) => a.collega.lastName > b.collega.lastName);

          const opoRespAssignment = ola.assignments.filter(a => a.opoResponsible);
          if (opoRespAssignment.length > 0) {
            ola.assignments = sortBy(ola.assignments, [
              o => !o.opoResponsible,
              o => o.collegaID !== opoRespAssignment[0].collegaID,
              "collega.lastName",
              "collega.firstName",
            ]);
          } else {
            ola.assignments = sortBy(ola.assignments, ["collega.lastName", "collega.firstName"]);
          }

          ola.phases.forEach(phase => {
            ola.classes += phase.numberOfClassesOverride || phase.phase.numberOfClasses;
          });

          ola.show = true;
        });
        allOLAs.push(...opo.olas);
      });
      runFilter(allOLAs, { general, cs, ects });

      // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
      if (!localStorage.getItem("SAT-PersonalProgrammeFilter")) {
        localStorage.setItem("SAT-PersonalProgrammeFilter", JSON.stringify([]));
      }

      let personalProgrammeIDs = [];
      JSON.parse(localStorage.getItem("SAT-PersonalProgrammeFilter")).forEach(selectedProgramme => {
        personalProgrammeIDs = [...personalProgrammeIDs, selectedProgramme.ID];
      });
      setSelectedProgrammeIDs(personalProgrammeIDs);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  useEffect(() => {
    load();
  }, [snapshot]);

  const toggleSelectedProgrammeFiler = (id, checked) => {
    if (checked) {
      setSelectedProgrammeIDs([...selectedProgrammeIDs, id]);
    } else {
      setSelectedProgrammeIDs(selectedProgrammeIDs.filter(i => i !== id));
    }
  };

  const toggleSelectedTag = (tag, checked) => {
    if (checked) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    }
  };

  const toggleSelectedPhaseID = (id, checked) => {
    if (checked) {
      setSelectedPhaseIDs([...selectedPhaseIDs, id]);
    } else {
      setSelectedPhaseIDs(selectedPhaseIDs.filter(i => i !== id));
    }
  };

  const updateAssignment = (olaIndex, classIndex, data) => {
    const newOLAs = [...olas];
    newOLAs[olaIndex].assignments[classIndex] = data;

    setOLAs(newOLAs);
    markAsChanged(newOLAs[olaIndex].ID);
  };

  const removeAssignment = (olaIndex, classIndex) => {
    if (olas[olaIndex].assignments[classIndex].ID) {
      setDeletedAssignments([...deletedAssignments, olas[olaIndex].assignments[classIndex]]);
    }

    const newOLAs = [...olas];
    newOLAs[olaIndex].assignments.splice(classIndex, 1);
    setOLAs(newOLAs);
    markAsChanged(newOLAs[olaIndex].ID);
  };

  const openEdit = (olaIndex, classIndex, data) => {
    setModal(
      <AssignmentEdit
        hideModal={() => {
          setModal(null);
        }}
        data={data}
        ola={olas[olaIndex]}
        saveData={d => updateAssignment(olaIndex, classIndex, d)}
        removeAssignment={() => removeAssignment(olaIndex, classIndex)}
      />,
    );
  };

  const openVTESimulationModal = ola => {
    setVTEModal(
      <VTESimulationModal
        hideModal={() => {
          setVTEModal(null);
        }}
        data={ola}
      />,
    );
  };

  const save = async () => {
    setSaving(true);
    const unblock = history.block("SAT is nog aan het opslaan, ben je zeker dat je deze pagina wilt verlaten?");
    try {
      await Promise.all(
        olas
          .filter(ola => hasChanges[ola.ID])
          .map(ola =>
            ola.assignments.map(async assignment => {
              assignment.olaID = ola.ID;
              if (assignment.ID) {
                await api.updateAssignment(assignment.ID, JSON.parse(JSON.stringify(assignment, getCircularReplacer())));
              } else {
                await api.createAssignment(JSON.parse(JSON.stringify(assignment, getCircularReplacer())));
              }
            }),
          ),
      );

      await Promise.all(
        deletedAssignments.map(async assignment => {
          await api.deleteAssignment(assignment.ID);
        }),
      );
      setDeletedAssignments([]);
      setHasChanges({});
      await load(false);

      api.createSnapshot();
      toastService.send({ title: "Saved", message: "Saved successfully" });
      unblock();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }

    setSaving(false);
  };

  useEffect(() => {
    // no OLAs left? keep filters the same
    if (olas.filter(ola => ola.show).length === 0) {
      return;
    }
    // calculate tags
    const opotags = {};
    olas.forEach(ola => {
      if (!ola.show) return;
      ola.opo.tags.forEach(tag => {
        if (!opotags[tag.name]) {
          opotags[tag.name] = true;
        }
      });
    });
    setTags(Object.keys(opotags));

    // calculate phases
    const opophases = {};
    olas.forEach(ola => {
      ola.phases.forEach(phase => {
        if (!opophases[phase.phaseID] && (selectedProgrammeIDs.includes(phase.phase.programmeID) || selectedProgrammeIDs.length === 0)) {
          opophases[phase.phaseID] = true;
        }
      });
    });
    setPhaseIDs(Object.keys(opophases));
  }, [olas]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }

  const popover = o => (
    <Popover className="docent-list">
      <Popover.Header as="h3">Docent toekennen</Popover.Header>
      <Popover.Body>
        <DocentList
          programme={o.opo.programme}
          selectDocent={d => {
            setOLAs(
              olas.map(ola => {
                if (ola.ID === o.ID) {
                  ola.assignments.push({
                    collega: d,
                    collegaID: d.ID,
                    internshipStudents: 0,
                    percentageOverride: 0,
                    ola,
                    olaID: o.ID,
                    opoResponsible: ola.assignments.length === 0 && !ola.internship,
                    comment: "",
                  });
                }
                return ola;
              }),
            );
            markAsChanged(o.ID);
          }}
          ola={o.internship && o}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <Container>
      {modal}
      {VTEModal}
      <Row>
        <Col>
          <h1>Vakken</h1>
        </Col>
        <Col>
          <Button variant="success" onClick={save} disabled={saving || snapshot} className="float-end fixedElement">
            <i className="far fa-save" />
            &nbsp;Opslaan
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={2}>
          <h3>Filter</h3>
          <hr />
          <h4>Opleiding</h4>
          <ul className="list-group">
            {programmes.map(programme => (
              <li key={programme.ID} className="list-group-item">
                <Form.Check
                  inline
                  label={programme.name}
                  id={programme.ID}
                  type="checkbox"
                  checked={selectedProgrammeIDs.includes(programme.ID)}
                  onChange={e => toggleSelectedProgrammeFiler(programme.ID, e.target.checked)}
                />
              </li>
            ))}
          </ul>
          <h4>Fasen</h4>
          <ul className="list-group">
            {phaseIDs.map(pid => (
              <li key={pid} className="list-group-item">
                <Form.Check
                  inline
                  label={(phases.filter(p => p.ID === parseInt(pid, 10))[0] || {}).name}
                  id={pid}
                  type="checkbox"
                  onChange={e => toggleSelectedPhaseID(parseInt(pid, 10), e.target.checked)}
                />
              </li>
            ))}
          </ul>
          <h4>Tags</h4>
          <ul className="list-group">
            {tags.map(tag => (
              <li key={tag} className="list-group-item">
                <Form.Check inline label={tag} id={tag} type="checkbox" onChange={e => toggleSelectedTag(tag, e.target.checked)} />
              </li>
            ))}
          </ul>
        </Col>
        <Col md={10}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Z-Code</th>
                <th>Naam</th>
                <th>Opleiding</th>
                <th className="assigncol">Invulling</th>
              </tr>
            </thead>
            <tbody>
              {olas.map((c, olaIndex) =>
                c.internship ? (
                  <StageOLA
                    c={c}
                    className={c.show ? "" : "d-none"}
                    olaIndex={olaIndex}
                    openVTESimulationModal={openVTESimulationModal}
                    openEdit={openEdit}
                    popover={popover}
                  />
                ) : (
                  <tr key={c.ID} className={c.show ? "" : "d-none"}>
                    <td>{c.opo.zCode} {c.zCode}</td>
                    <td>
                      <Button type="button" variant="link" className="force-left" onClick={() => openVTESimulationModal(c)}>
                        {c.name}
                      </Button>
                      <br />
                      <i className="fas fa-arrow-right" />
                      &nbsp;{c.vte.toFixed(4)}&nbsp;VTE
                    </td>
                    <td>{c.opo.programme.name}</td>
                    <td>
                      <Row className="mx-2">
                        {Array.from(Array(c.classes))
                          .map((_, i) => i)
                          .map(i => (
                            <Col xs={2} className="my-2 px-1" key={i}>
                              {c.assignments.length < i + 1 ? (
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popover(c)}>
                                  <Button className="plusButton">
                                    <i className="fas fa-plus" />
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                <Button variant="outline-dark" className="collegaButton" onClick={() => openEdit(olaIndex, i, c.assignments[i])}>
                                  <div className="collega-name text-truncate">
                                    {c.assignments[i].opoResponsible && (
                                      <span>
                                        <i className="far fa-award" />{" "}
                                      </span>
                                    )}{" "}
                                    {localStorage.getItem("SAT-typeName") === "volledig"
                                      ? c.assignments[i].collega.firstName
                                      : c.assignments[i].collega.initials}
                                  </div>

                                  {localStorage.getItem("SAT-typeName") === "volledig" && (
                                    <div className="collega-name text-truncate">{c.assignments[i].collega.lastName}</div>
                                  )}

                                  <div className="fw-bold assignmentVTE">
                                    {c.assignments[i].percentageOverride !== 0 && (
                                      <span className="text-warning">
                                        <i className="fas fa-bolt" />&nbsp;
                                      </span>
                                    )}
                                    {c.assignments[i].numberOfAssignments > 1 && (
                                      <span>{(c.assignments[i].collegaVTE / c.assignments[i].numberOfAssignments).toFixed(1)}%/</span>
                                    )}
                                    {c.assignments[i].collegaVTE}%
                                    {c.assignments[i].comment !== "" && (
                                      <span className="text-info">
                                        &nbsp;<i className="far fa-comment-dots" />
                                      </span>
                                    )}
                                  </div>
                                </Button>
                              )}
                            </Col>
                          ))}
                      </Row>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default OLAAssignment;
