import { Modal, Row, Form, Col, Button } from "react-bootstrap";

function InvalidProgrammeModal({ hideModal }) {
  const onFormSubmit = async e => {
    e.preventDefault();
    hideModal();
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Percentage zonder opleiding</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Er is een percentage toegekend zonder opleiding te selecteren. Gelieve een opleiding hieraan te koppelen.</Form.Label>
              </Form.Group>

              <Button variant="danger" type="submit">
                <span>
                  <i className="fas fa-wrench" />
                  &nbsp;I&apos;ll fix it!
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default InvalidProgrammeModal;
