import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { columnsDynamicData, saveChanges, getNewKanbanAssignment } from "./KanbanData";
import SatAPI from "../../../_services/sat";
import toastService from "../../../_services/toastService";
import { getContactHours, loadParameters, calculateRoundedCollegaVTEPreFetched, getFilteredAvailability, getVTEForCollegaByProgrammes } from "../../../_helpers/calculations";
import KanbanColumn from "./KanbanColumn";
import "../../../scss/Kanban/Kanban.scss";
import KanbanModal from "./KanbanModal";

const api = SatAPI();

function Kanban() {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [olaAssignments, setOlaAssignments] = useState([]);
  const [taskAssignments, setTaskAssignments] = useState([]);
  const [parallels, setParallels] = useState([]);
  const [collegas, setCollegas] = useState([]);

  const [originalData, setOriginalData] = useState({}); // object to store the original data in JSON strings

  const [parameters, setParameters] = useState(null);

  // filter
  const [selectedCollegas, setSelectedCollegas] = useState(JSON.parse(localStorage.getItem("SAT-selectedCollegas")) || []);

  const getOLAAssignmentVisibility = (assignment, programmes) => {
    let match = false;

    if (programmes !== null) {
      programmes.forEach(programme => {
        if (assignment.ola.phases[0].phase.programmeID === programme.ID) {
          match = true;
        }
      });
    }

    if (programmes.length === 0) {
      match = true; // no filter on programmes => everything visible
    }

    return match;
  };

  const getTaskAssignmentVisibility = (assignment, programmes) => {
    let match = false;

    if (programmes !== null) {
      programmes.forEach(programme => {
        if (assignment.programmeID === programme.ID) {
          match = true;
        }
      });
    }

    if (programmes.length === 0) {
      match = true; // no filter on programmes => everything visible
    }

    return match;
  };

  const loadCollegas = async () => {
    try {
      setLoading(true);
      const collegaResponse = await api.getCollegas(snapshot);

      const assignments = await api.getAssignments();
      setOlaAssignments(assignments);

      const oposResponse = await api.getOPOs(snapshot);
      oposResponse.sort((a, b) => b.name < a.name ? 1 : -1);
      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }

      const allOLAsFromOPOS = [];
      oposResponse.forEach(opo => {
        opo.olas.forEach(ola => {
          allOLAsFromOPOS.push(ola);
        });
      });

      // TODO: improve this in new back-end!
      // Overwrite the OLA's in parallel because they don't contain OLA phases,
      // which is necessary for calculation of student numbers
      currentParallels.forEach(parallel => {
        parallel.mainOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.mainOLAID);
        parallel.subOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.subOLAID);
      });

      setParallels(currentParallels);

      const taskAssignmentsFromAPI = await api.getTaskAssignments();
      setTaskAssignments(taskAssignmentsFromAPI);

      setOriginalData({
        assignments: JSON.stringify(assignments),
        taskAssignments: JSON.stringify(taskAssignmentsFromAPI),
      });

      const { general, cs, ects } = await loadParameters();
      setParameters({ general, cs, ects });

      console.log("Collegas: ", collegaResponse);
      console.log("Assignments: ", assignments);
      console.log("Task assignments: ", taskAssignmentsFromAPI);
      if (localStorage.getItem("SAT-typeSorting") === "firstname") {
        collegaResponse.sort((a, b) => b.firstName < a.firstName);
      } else {
        collegaResponse.sort((a, b) => b.lastName < a.lastName);
      }
      collegaResponse.forEach(collega => {
        collega.contactHoursQ1 = getContactHours(collega, assignments, 1);
        collega.contactHoursQ2 = getContactHours(collega, assignments, 2);
        collega.contactHoursQ3 = getContactHours(collega, assignments, 3);
        collega.contactHoursQ4 = getContactHours(collega, assignments, 4);

        collega.currentVTE = calculateRoundedCollegaVTEPreFetched(collega, assignments, taskAssignmentsFromAPI, currentParallels, assignments, general, cs, ects);

        collega.olaAssignments = assignments.filter(c => c.collegaID === collega.ID) || [];
        collega.olaAssignments.sort((a, b) => (b.ola || {}).name < (a.ola || {}).name);

        collega.taskAssignments = taskAssignmentsFromAPI.filter(c => c.collegaID === collega.ID) || [];
        collega.taskAssignments.sort((a, b) => b.task.name < a.task.name);

        // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
        if (!localStorage.getItem('SAT-PersonalProgrammeFilter')) {
          localStorage.setItem('SAT-PersonalProgrammeFilter', JSON.stringify([]));
        }

        // for filtering on programme
        collega.filteredProgrammes = JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter'));
        collega.filteredProgrammesLength = collega.filteredProgrammes.length;
        collega.percentageAvailableFiltered = getFilteredAvailability(collega, collega.filteredProgrammes);
        collega.filteredVTE = getVTEForCollegaByProgrammes(collega, collega.olaAssignments, collega.taskAssignments, currentParallels, assignments, general, cs, ects, collega.filteredProgrammes);
        collega.olaAssignments.forEach(assignment => {
          assignment.show = getOLAAssignmentVisibility(assignment, collega.filteredProgrammes);
        })

        collega.taskAssignments = taskAssignmentsFromAPI.filter(c => c.collegaID === collega.ID) || [];
        collega.taskAssignments.sort((a, b) => b.task.name < a.task.name);
        collega.taskAssignments.forEach(assignment => {
          assignment.show = getTaskAssignmentVisibility(assignment, collega.filteredProgrammes);
        })

        // for multiselect
        collega.label = `${collega.firstName} ${collega.lastName}`;
        collega.value = collega.ID;

        // for filtering
        collega.show = true;
      });

      setCollegas(collegaResponse);

      setColumns(columnsDynamicData(collegaResponse, general));
      setSelectedCollegas(JSON.parse(localStorage.getItem("SAT-selectedCollegas")) || []);
      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };

  const onDragEnd = result => {
    if (result.reason !== "DROP") {
      return; // only on drop for now
    }

    const { destination, source } = result;

    if (!destination || !source) {
      console.log("no destination or source", destination, source);
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      console.log("index and destination the same");
      return;
    }

    console.log("COLUMS", columns);
    const start = columns.find(c => c.collegaID === parseInt(source.droppableId, 10));
    const finish = columns.find(c => c.collegaID === parseInt(destination.droppableId, 10));
    console.log("Start:", start);
    console.log("Finish:", finish);
    if (!start || !finish) {
      console.log("no start or finish", start, finish);
      return;
    }

    if (start.collegaID === finish.collegaID) {
      // same col we will just reorder
      const newTaskIds = Array.from(start.taskIds);

      const swapTask = newTaskIds[source.index];
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, swapTask);

      setColumns(
        columns.map(c => {
          if (c.id === start.id) {
            c.taskIds = newTaskIds;
          }
          return c;
        }),
      );
    } else if (finish.taskIds.length < finish.limit) {
      // moved task to another col
      const startTaskIds = Array.from(start.taskIds);
      const [item] = startTaskIds.splice(source.index, 1);

      const finishTaskIds = Array.from(finish.taskIds);
      finishTaskIds.splice(destination.index, 0, item);

      let newColumnsState = columns.map(c => {
        if (c.collegaID === start.collegaID) {
          c.taskIds = startTaskIds;
        } else if (c.collegaID === finish.collegaID) {
          c.taskIds = finishTaskIds;
        }
        return c;
      });
      console.log("New column state:", newColumnsState);

      const newData = getNewKanbanAssignment(newColumnsState, olaAssignments, taskAssignments);
      // update col data
      newColumnsState = columns.map(c => {
        if (c.collegaID === start.collegaID || c.collegaID === finish.collegaID) {
          c.contactHoursQ1 = getContactHours(c.collega[0], newData.olaAssignments, 1);
          c.contactHoursQ2 = getContactHours(c.collega[0], newData.olaAssignments, 2);
          c.contactHoursQ3 = getContactHours(c.collega[0], newData.olaAssignments, 3);
          c.contactHoursQ4 = getContactHours(c.collega[0], newData.olaAssignments, 4);
          c.currentVTE = calculateRoundedCollegaVTEPreFetched(
            c.collega[0],
            newData.olaAssignments,
            newData.taskAssignments,
            parallels,
            olaAssignments,
            parameters.general,
            parameters.cs,
            parameters.ects,
          );
          c.filteredVTE = getVTEForCollegaByProgrammes(
            c.collega[0],
            newData.olaAssignments,
            newData.taskAssignments,
            parallels,
            olaAssignments,
            parameters.general,
            parameters.cs,
            parameters.ects,
            c.collega[0].filteredProgrammes);
        }
        return c;
      });

      setColumns(newColumnsState);
    }
  };

  const openModal = data => {
    const columnId = data.id;
    setModal(columnId);
  };

  const closeModal = () => {
    setModal(false);
  };

  const addTask = newTask => {
    setModal(false);
    const updatedColumns = columns.map(column => {
      if (column.id === newTask.idColumn && column.taskIds.length < 5) {
        column.taskIds.push(newTask);
        return column;
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  const removeTask = taskId => {
    const updatedColumns = columns
      .map(column => ({
        ...column,
        taskIds: column.taskIds.filter(task => task.id !== taskId),
      }))
      .filter(column => column.taskIds.length >= 0);
    setColumns(updatedColumns);
  };

  const editTask = (taskId, newUser, newText) => {
    const updatedColumns = columns.map(column => ({
      ...column,
      taskIds: column.taskIds.map(task => {
        if (task.id === taskId) {
          task.user = newUser;
          task.text = newText;
          return task;
        }
        return task;
      }),
    }));
    setColumns(updatedColumns);
  };

  const save = async () => {
    try {
      setLoading(true);

      const originalAssignments = JSON.parse(originalData.assignments);
      const originalTaskAssignments = JSON.parse(originalData.taskAssignments);
      await saveChanges(columns, originalAssignments, originalTaskAssignments);
      api.createSnapshot();

      // reload assignments
      const assignments = await api.getAssignments();
      setOlaAssignments(assignments);

      const taskAssignmentsFromAPI = await api.getTaskAssignments();
      setTaskAssignments(taskAssignmentsFromAPI);

      setOriginalData({
        assignments: JSON.stringify(assignments),
        taskAssignments: JSON.stringify(taskAssignmentsFromAPI),
      });

      setLoading(false);
    } catch (error) {
      console.log("error saving", error);
      toastService.send({ title: "An error occured", message: error });
    }
  };

  useEffect(() => {
    loadCollegas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshot]);

  useEffect(() => {
    if (selectedCollegas.length === 0) {
      setColumns(
        columns.map(column => {
          column.show = !(column.filteredProgrammesLength > 0 && column.percentageAvailableFiltered === 0);

          return column;
        }),
      );

      localStorage.setItem("SAT-selectedCollegas", JSON.stringify(selectedCollegas));
      return;
    }
    setColumns(
      columns.map(column => {
        if (selectedCollegas.filter(collega => collega.ID === column.id).length > 0) {
          column.show = true;
        } else {
          column.show = false;
        }

        localStorage.setItem("SAT-selectedCollegas", JSON.stringify(selectedCollegas));
        return column;
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollegas]);

  const popover = () => (
    <Popover>
      <Popover.Header as="h3">Filteren</Popover.Header>
      <Popover.Body>
        <h5>Docenten</h5>
        <MultiSelect options={collegas} value={selectedCollegas} onChange={setSelectedCollegas} labelledBy="Select" className="docenten-selector" />
      </Popover.Body>
    </Popover>
  );

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="Kanban">
          {modal && <KanbanModal closeModal={closeModal} addTask={addTask} columnData={modal} />}

          <div className="float-end fixedElement">
            <OverlayTrigger trigger="click" placement="left" overlay={popover()}>
              <Button variant="secondary" className="me-2">
                <i className="far fa-filter" />
              </Button>
            </OverlayTrigger>
            <Button variant="success" onClick={save} disabled={snapshot}>
              <i className="far fa-save" />
              &nbsp;Opslaan
            </Button>
          </div>

          <div className="Kanban-columns-container">
            {columns.map(c => (
              <KanbanColumn columnData={c} key={c.name} openModal={openModal} removeTask={removeTask} editTask={editTask} />
            ))}
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

export default Kanban;
