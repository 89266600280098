import { useEffect } from "react";
import { Modal, Row, Col, Table } from "react-bootstrap";
// import { calculateOPOVTEWorstCase, calculateOPOVTEBestCase } from "../../../../_helpers/calculations";

function AssignmentEdit({ data = {}, hideModal }) {
  /* const [worstCaseVTE, setVTEWorstCase] = useState(0);
    const [bestCaseVTE, setVTEBestCase] = useState(0);
    const load = async () => {
        const newWorstCaseVTE = await calculateOPOVTEWorstCase([data]);
        setVTEWorstCase(newWorstCaseVTE);
        const newBestCaseVTE = await calculateOPOVTEBestCase([data]);
        setVTEBestCase(newBestCaseVTE);
    }; */

  useEffect(() => {
    // load();
  }, [data]);

  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Info - {data.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            {/* <p className="font-weight-bold">Maximale begroting OPO: worstCaseVTE VTE</p>
                        <p className="font-weight-bold">Minimale begroting OPO: bestCaseVTE VTE</p> */}
            <p>Studiepunten: {data.ectsCredits}</p>
            <p>Z-code OPO / OLA: {data.opo.zCode} / {data.zCode}</p>
            <p>Contacturen:</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Q1</th>
                  <th>Q2</th>
                  <th>Q3</th>
                  <th>Q4</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data.contactQ1}</td>
                  <td>{data.contactQ2}</td>
                  <td>{data.contactQ3}</td>
                  <td>{data.contactQ4}</td>
                </tr>
              </tbody>
            </Table>
            <p>Fase(s):</p>
            <ul>
              {data.phases.map(phase => (
                <li>
                  {phase.phase.name} ({phase.phase.numberOfStudents + phase.surplus} studenten - {phase.numberOfClassesOverride || phase.phase.numberOfClasses}{" "}
                  klassen)
                </li>
              ))}
            </ul>
            <p>Is een stage: {data.internship ? "ja" : "nee"}</p>
            <p>
              Opmerkingen:
              <br />
              <span className="text-muted">{data.opo.comments}</span>
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AssignmentEdit;
