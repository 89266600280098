import { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import StringFilter from '@inovua/reactdatagrid-community/StringFilter'
import '@inovua/reactdatagrid-community/index.css'
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";
import toastService from "../../_services/toastService";
import { downloadBlob } from "../../_helpers/excel";

import SatAPI from "../../_services/sat";

const api = SatAPI();

function Parallels() {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [parallels, setParallels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);
  const [gridRef, setGridRef] = useState(null);

  const loadParallels = async (setLoad = true) => {
    try {
      setLoading(setLoad);
      const opos = await api.getOPOs(snapshot);
      opos.sort((a, b) => b.name < a.name ? 1 : -1);
      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }

      const allOLAs = [];
      opos.forEach(opo => {
        opo.olas.forEach(ola => {
          allOLAs.push(ola);
        });
      });

      // TODO: improve this in new back-end!
      // Overwrite the OLA's in parallel because they don't contain OLA phases,
      // which is necessary for calculation of student numbers
      currentParallels.forEach(parallel => {
        parallel.mainOLA = allOLAs.find((ola) => ola.ID === parallel.mainOLAID);
        parallel.mainOLAName = parallel.mainOLA.name; // necessary for sorting in datagrid
        parallel.mainOLAProgrammeName = opos.find((opoforProgramme) => opoforProgramme.ID === parallel.mainOLA.opoID).programme.name; // necessary for sorting in datagrid
        parallel.subOLA = allOLAs.find((ola) => ola.ID === parallel.subOLAID);
        parallel.subOLAName = parallel.subOLA.name; // necessary for sorting in datagrid
        parallel.subOLAProgrammeName = opos.find((opoforProgramme) => opoforProgramme.ID === parallel.subOLA.opoID).programme.name; // necessary for sorting in datagrid
      });

      setParallels(currentParallels);
      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  const openEditModal = parallel => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadParallels();
        }}
        data={parallel}
      />,
    );
  };

  const openRemoveModal = parallel => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadParallels();
        }}
        data={parallel}
      />,
    );
  };

  const defaultSortInfo = { name: 'mainOLAName', dir: -1 }

  const gridStyle = { minHeight: 550 }

  const initialColumns = [
    {
      name: 'mainOLAName', header: 'Hoofdvak', defaultFlex: 1, sortable: true,
      filterEditor: StringFilter
    },
    {
      name: 'mainOLAProgrammeName', header: 'Opleiding hoofdvak', defaultFlex: 1, sortable: true,
      filterEditor: StringFilter
    },
    {
      name: 'subOLAName', header: 'Parallelvak', defaultFlex: 1, sortable: true,
      filterEditor: StringFilter
    },
    {
      name: 'subOLAProgrammeName', header: 'Opleiding parallelvak', defaultFlex: 1, sortable: true,
      filterEditor: StringFilter
    },
    { name: 'level', header: 'Niveau', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    {
      name: 'ID', header: 'Acties', defaultFlex: 1, draggable: false, sortable: false, render: ({ data }) => <div>
        <Button variant="info" onClick={() => openEditModal(data)} disabled={snapshot}>
          <i className="far fa-edit" />
        </Button>
        <Button variant="danger" onClick={() => openRemoveModal(data)} disabled={snapshot}>
          <i className="far fa-trash" />
        </Button></div>
    }
  ];

  const [columns] = useState(initialColumns);

  const filterValue = [
    { name: 'mainOLAName', operator: 'contains', type: 'string', value: '' },
    { name: 'mainOLAProgrammeName', operator: 'contains', type: 'string', value: '' },
    { name: 'subOLAName', operator: 'contains', type: 'string', value: '' },
    { name: 'subOLAProgrammeName', operator: 'contains', type: 'string', value: '' },
    { name: 'level', operator: 'inrange', type: 'number', value: 0 }
  ];

  const exportCSV = () => {
    const { visibleColumns } = gridRef.current;

    const header = visibleColumns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => visibleColumns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob, 'parallelvakken.csv');
  };

  useEffect(() => {
    loadParallels();
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }

  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Parallelvakken</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportCSV()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>{" "}
          <Button variant="success" onClick={() => openEditModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactDataGrid
            handle={setGridRef}
            idProperty="ID"
            defaultSortingInfo={defaultSortInfo}
            style={gridStyle}
            defaultFilterValue={filterValue}
            columns={columns}
            dataSource={parallels}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Parallels;
