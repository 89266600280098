/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Container, Col, Button, Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function PersonalSetup() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [programmes, setProgrammes] = useState([]);
  const [selectedProgrammes, setSelectedProgrammes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [typeName, setTypeName] = useState(localStorage.getItem("SAT-typeName") || "volledig");
  const [typeSorting, setTypeSorting] = useState(localStorage.getItem("SAT-typeSorting") || "lastname");
  const [look, setLook] = useState(localStorage.getItem("SAT-look") || "classic");

  const load = async () => {
    try {
      setLoading(true);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name);
      response.forEach(programme => {
        // for multiselect
        programme.label = programme.name;
        programme.value = programme.ID;
      });
      setProgrammes(response);

      const selectedProgrammesOnLoad = JSON.parse(localStorage.getItem("SAT-PersonalProgrammeFilter"));
      if (selectedProgrammesOnLoad !== null) {
        setSelectedProgrammes(selectedProgrammesOnLoad);
      }

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  useEffect(() => {
    load();
  }, [snapshot]);

  const save = async () => {
    setLoading(true);
    localStorage.setItem("SAT-PersonalProgrammeFilter", JSON.stringify(selectedProgrammes));
    localStorage.setItem("SAT-typeName", typeName);
    localStorage.setItem("SAT-typeSorting", typeSorting);
    localStorage.setItem("SAT-look", look);
    setLoading(false);
    window.location.reload();
  };

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      <Row>
        <Col>
          <h1>Mijn instellingen</h1>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={4}>
          <h3><i className="fas fa-filter" /> Filter opleiding(en)</h3>
          <MultiSelect options={programmes} value={selectedProgrammes} onChange={setSelectedProgrammes} labelledBy="Select" className="programme-selector" />
        </Col>
        <Col md={4}>
          <h3><i className="fas fa-text" /> Docent Namen</h3>
          <Form.Group>
            <Form.Select onChange={e => setTypeName(e.target.value)} value={typeName}>
              <option value={null} disabled>
                Selecteer een optie
              </option>
              <option value="volledig">Volledig</option>
              <option value="initials">Initialen</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <h3><i className="fas fa-sort-alpha-down" /> Docent Sortering</h3>
          <Form.Group>
            <Form.Select onChange={e => setTypeSorting(e.target.value)} value={typeSorting}>
              <option value={null} disabled>
                Selecteer een optie
              </option>
              <option value="firstname">Voornaam</option>
              <option value="lastname">Achternaam</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={4}>
          <h3><i className="fas fa-lamp-floor" /> Look &amp; Feel</h3>
          <Form.Group>
            <Form.Select onChange={e => setLook(e.target.value)} value={look}>
              <option value={null} disabled>
                Selecteer een optie
              </option>
              <option value="volledig">SAT Original Taste</option>
              <option value="tiny">SAT Zero Margin</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <Button variant="success" onClick={save} disabled={loading || snapshot}>
            <i className="far fa-save" />
            &nbsp;Opslaan
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default PersonalSetup;
