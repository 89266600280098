import { Modal, Row, Form, Col } from "react-bootstrap";

function MessageModal({ title, message, icon, hideModal }) {
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="sat-message-modal">
      <Modal.Header closeButton>
        <Modal.Title>{icon && <i className={icon} />} {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>{message}</Form.Label>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default MessageModal;
