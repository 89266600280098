/* eslint-disable import/no-duplicates */
import { useEffect, useState } from "react";
import sortBy from "lodash.sortby";
import { Row, Container, Col, Table, Button, Popover, OverlayTrigger, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import SatAPI from "../../_services/sat";
import toastService from "../../_services/toastService";
import { loadParametersPerformanceScheme, calculateRoundedCollegaVTEPreFetched, getContactHours } from "../../_helpers/calculations";
import { calculateGraduatesForfait } from "../../_helpers/graduatesimport";

const api = SatAPI();

function PerformanceScheme() {
    const snapshot = useSelector(state => state.snapshot.fileName);
    const [collega, setCollega] = useState([]);
    const [olaAssignments, setOlaAssignments] = useState([]);
    const [taskAssignments, setTaskAssignments] = useState([]);
    const [parallels, setParallels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSATUser, setIsSATUser] = useState(false);
    const [collegas, setCollegas] = useState([]);
    const [collegaID, setCollegaID] = useState(-1);

    const loadPerformanceScheme = async () => {
        try {
            setLoading(true);

            const shownOlas = [];
            let coveredCollegas = [];

            console.log(snapshot);
            const response = await api.getMyself();
            let assignmentsFromAPI = await api.getMyAssignments();
            assignmentsFromAPI = assignmentsFromAPI.sort((a, b) => a.ola.semester > b.ola.semester);
            const taskAssignmentsFromAPI = await api.getMyTaskAssignments();
            console.log("My assignments:", assignmentsFromAPI);
            console.log("My task assignments:", taskAssignmentsFromAPI);

            const oposResponse = await api.getOPOs(snapshot);
            oposResponse.sort((a, b) => b.name < a.name ? 1 : -1);
            let currentParallels = [];
            if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
                currentParallels = await api.getParallels(snapshot);
                currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
            }

            const allOLAsFromOPOS = [];
            oposResponse.forEach(opo => {
                opo.olas.forEach(ola => {
                    allOLAsFromOPOS.push(ola);
                });
            });

            // TODO: improve this in new back-end!
            // Overwrite the OLA's in parallel because they don't contain OLA phases,
            // which is necessary for calculation of student numbers
            currentParallels.forEach(parallel => {
                parallel.mainOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.mainOLAID);
                parallel.subOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.subOLAID);
            });

            setParallels(currentParallels);

            const { general, cs, ects } = await loadParametersPerformanceScheme();
            response.olaVTE = calculateRoundedCollegaVTEPreFetched(response, assignmentsFromAPI, [], currentParallels, assignmentsFromAPI, general, cs, ects, false);
            response.taskVTE = calculateRoundedCollegaVTEPreFetched(response, [], taskAssignmentsFromAPI, [], [], general, cs, ects, false);
            response.totalVTE = calculateRoundedCollegaVTEPreFetched(response, assignmentsFromAPI, taskAssignmentsFromAPI, currentParallels, assignmentsFromAPI, general, cs, ects, true);
            response.graduatesForfait = calculateGraduatesForfait(response, taskAssignmentsFromAPI);
            assignmentsFromAPI
                .filter(b => b.collegaID === response.ID)
                .forEach(a => {
                    coveredCollegas = [];
                    a.vte = calculateRoundedCollegaVTEPreFetched(
                        a.collega,
                        assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID),
                        [],
                        currentParallels,
                        assignmentsFromAPI,
                        general,
                        cs,
                        ects,
                        false,
                    );
                    a.numberOfGroups = assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID).length;

                    if (a.ola.assignments) {
                        const opoRespAssignment = a.ola.assignments.filter(o => o.opoResponsible);
                        if (opoRespAssignment.length > 0) {
                            a.ola.assignments = sortBy(a.ola.assignments, [
                                o => !o.opoResponsible,
                                o => o.collegaID !== opoRespAssignment[0].collegaID,
                                "collega.lastName",
                                "collega.firstName",
                            ]);
                        } else {
                            a.ola.assignments = sortBy(a.ola.assignments, ["collega.lastName", "collega.firstName"]);
                        }
                    }

                    console.log(a.ola);
                    if (a.ola.assignments) {
                        a.ola.assignments.forEach(ca => {
                            ca.numberOfAssignments = a.ola.assignments.filter(b => b.collegaID === ca.collegaID).length;

                            if (coveredCollegas.indexOf(ca.collegaID) > -1) {
                                ca.show = false;
                            } else {
                                ca.show = true;
                                coveredCollegas.push(ca.collegaID);
                            }
                        });
                    }

                    console.log("Collega assignments:", a.collegaAssignments);
                    if (shownOlas.indexOf(a.ola.ID) > -1) {
                        a.show = false;
                    } else {
                        a.show = true;
                        a.contactHoursQ1 = getContactHours(
                            a.collega,
                            assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID),
                            1,
                        );
                        a.contactHoursQ2 = getContactHours(
                            a.collega,
                            assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID),
                            2,
                        );
                        a.contactHoursQ3 = getContactHours(
                            a.collega,
                            assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID),
                            3,
                        );
                        a.contactHoursQ4 = getContactHours(
                            a.collega,
                            assignmentsFromAPI.filter(b => b.collegaID === response.ID && b.ola.ID === a.ola.ID),
                            4,
                        );

                        shownOlas.push(a.ola.ID);
                    }
                });

            taskAssignmentsFromAPI
                .filter(b => b.collegaID === response.ID)
                .forEach(a => {
                    if (a.task.name.toLowerCase() === "forfait") {
                        a.show = false;
                    } else {
                        a.show = true;
                    }
                });

            setOlaAssignments(assignmentsFromAPI);
            setTaskAssignments(taskAssignmentsFromAPI);
            response.contactHoursQ1 = getContactHours(response, assignmentsFromAPI, 1);
            response.contactHoursQ2 = getContactHours(response, assignmentsFromAPI, 2);
            response.contactHoursQ3 = getContactHours(response, assignmentsFromAPI, 3);
            response.contactHoursQ4 = getContactHours(response, assignmentsFromAPI, 4);
            setCollega(response);

            // And now I wanne be...
            if (localStorage.getItem("isSATUser") === "true") {
                setIsSATUser(true);
                const allCollegas = await api.getCollegas(snapshot);
                if (localStorage.getItem("SAT-typeSorting") === "firstname") {
                    allCollegas.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
                } else {
                    allCollegas.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
                }
                setCollegas(allCollegas);
            }

            setLoading(false);
            return response;
        } catch (error) {
            toastService.send({ title: "An error occured", message: error });
        }
        return [];
    };

    const changeCollegaID = async changedCollegaID => {
        setLoading(true);

        const shownOlas = [];
        let coveredCollegas = [];

        if (changedCollegaID > -1) {
            console.log(snapshot);
            const newResponse = await api.getCollega(changedCollegaID);
            let newAssignmentsFromAPI = await api.getAssignments(snapshot);
            newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID);
            newAssignmentsFromAPI = newAssignmentsFromAPI.sort((a, b) => a.ola.semester > b.ola.semester);
            const newTaskAssignmentsFromAPI = await api.getTaskAssignments(snapshot);
            newTaskAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID);
            console.log("Assignments of", newResponse.firstName, newResponse.lastName, ":", newAssignmentsFromAPI);
            console.log("Task assignments of", newResponse.firstName, newResponse.lastName, ":", newTaskAssignmentsFromAPI);
            const { general, cs, ects } = await loadParametersPerformanceScheme();
            const allOpos = await api.getOPOs(snapshot);
            newResponse.olaVTE = calculateRoundedCollegaVTEPreFetched(newResponse, newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID), [], parallels, newAssignmentsFromAPI, general, cs, ects, false);
            newResponse.taskVTE = calculateRoundedCollegaVTEPreFetched(newResponse, [], newTaskAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID), parallels, [], general, cs, ects, false);
            newResponse.totalVTE = calculateRoundedCollegaVTEPreFetched(newResponse, newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID), newTaskAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID), parallels, newAssignmentsFromAPI, general, cs, ects, true);
            newResponse.graduatesForfait = calculateGraduatesForfait(newResponse, newTaskAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID));
            newAssignmentsFromAPI
                .filter(b => b.collegaID === changedCollegaID)
                .forEach(a => {
                    a.ola.assignments = newAssignmentsFromAPI.filter(b => b.olaID === a.olaID);
                    coveredCollegas = [];
                    a.vte = calculateRoundedCollegaVTEPreFetched(
                        a.collega,
                        newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID && b.ola.ID === a.ola.ID),
                        [],
                        parallels,
                        newAssignmentsFromAPI,
                        general,
                        cs,
                        ects,
                        false,
                    );
                    a.numberOfGroups = newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID && b.ola.ID === a.ola.ID).length;

                    if (a.ola.assignments) {
                        const opoRespAssignment = a.ola.assignments.filter(o => o.opoResponsible);
                        if (opoRespAssignment.length > 0) {
                            a.ola.assignments = sortBy(a.ola.assignments, [
                                o => !o.opoResponsible,
                                o => o.collegaID !== opoRespAssignment[0].collegaID,
                                "collega.lastName",
                                "collega.firstName",
                            ]);
                        } else {
                            a.ola.assignments = sortBy(a.ola.assignments, ["collega.lastName", "collega.firstName"]);
                        }
                    }

                    console.log(a.ola);

                    console.log("Collega assignments:", a.collegaAssignments);
                    if (shownOlas.indexOf(a.ola.ID) > -1) {
                        a.show = false;
                    } else {
                        a.show = true;
                        shownOlas.push(a.ola.ID);
                    }

                    // eslint-disable-next-line prefer-destructuring
                    a.ola.opo = allOpos.filter(opo => opo.ID === a.ola.opoID)[0];

                    if (a.ola.assignments) {
                        a.ola.assignments.forEach(ca => {
                            ca.numberOfAssignments = a.ola.assignments.filter(b => b.collegaID === ca.collegaID).length;

                            if (coveredCollegas.indexOf(ca.collegaID) > -1) {
                                ca.show = false;
                            } else {
                                ca.show = true;
                                coveredCollegas.push(ca.collegaID);
                                ca.contactHoursQ1 = getContactHours(
                                    ca.collega,
                                    newAssignmentsFromAPI.filter(b => b.collegaID === newResponse.ID && b.ola.ID === ca.ola.ID),
                                    1,
                                );
                                ca.contactHoursQ2 = getContactHours(
                                    ca.collega,
                                    newAssignmentsFromAPI.filter(b => b.collegaID === newResponse.ID && b.ola.ID === ca.ola.ID),
                                    2,
                                );
                                ca.contactHoursQ3 = getContactHours(
                                    ca.collega,
                                    newAssignmentsFromAPI.filter(b => b.collegaID === newResponse.ID && b.ola.ID === ca.ola.ID),
                                    3,
                                );
                                ca.contactHoursQ4 = getContactHours(
                                    ca.collega,
                                    newAssignmentsFromAPI.filter(b => b.collegaID === newResponse.ID && b.ola.ID === ca.ola.ID),
                                    4,
                                );
                            }
                        });
                    }
                });

            newTaskAssignmentsFromAPI
                .filter(b => b.collegaID === newResponse.ID)
                .forEach(a => {
                    if (a.task.name.toLowerCase() === "forfait") {
                        a.show = false;
                    } else {
                        a.show = true;
                    }
                });

            setOlaAssignments(newAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID));
            setTaskAssignments(newTaskAssignmentsFromAPI.filter(b => b.collegaID === changedCollegaID));
            newResponse.contactHoursQ1 = getContactHours(newResponse, newAssignmentsFromAPI, 1);
            newResponse.contactHoursQ2 = getContactHours(newResponse, newAssignmentsFromAPI, 2);
            newResponse.contactHoursQ3 = getContactHours(newResponse, newAssignmentsFromAPI, 3);
            newResponse.contactHoursQ4 = getContactHours(newResponse, newAssignmentsFromAPI, 4);
            setCollega(newResponse);
            setCollegaID(changedCollegaID);
        }

        setCollegaID(changedCollegaID);
        setLoading(false);
    };

    useEffect(() => {
        loadPerformanceScheme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshot]);

    const popover = () => (
        <Popover>
            <Popover.Header as="h3">Legende</Popover.Header>
            <Popover.Body>
                <h5>Symbolen</h5>
                <p>
                    <i className="far fa-award" /> OPO-coördinator
                </p>
            </Popover.Body>
        </Popover>
    );

    if (loading) {
        return (
            <div className="text-center">
                <i className="fad fa-spinner-third fa-spin fa-5x" />
            </div>
        );
    }
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Prestatieregeling academiejaar 2023-2024</h1>
                    <p>
                        <span className="font-bold">Naam:</span> {collega.firstName} {collega.lastName} ({collega.uNumber})
                    </p>
                </Col>
            </Row>
            {isSATUser && (
                <Row>
                    <Form.Group className="mb-3">
                        <Form.Select onChange={e => changeCollegaID(parseInt(e.target.value, 10))} value={collegaID}>
                            <option value={-1}>Andere collega bekijken</option>
                            {collegas.map(c => (
                                <option key={c.ID} value={c.ID}>
                                    {c.firstName} {c.lastName}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Row>
            )}
            <Row className="my-2">
                <Col>
                    <div className="float-end fixedElement">
                        <OverlayTrigger trigger="click" placement="left" overlay={popover()}>
                            <Button variant="info" className="me-2">
                                <i className="fas fa-question" />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h3>Onderwijsopdrachten: {collega.olaVTE.toFixed(2)} %</h3>
                        </Col>
                        <Col md={3}>
                            <Table striped bordered hover className="text-center">
                                <thead>
                                    <tr>
                                        <th>Q1</th>
                                        <th>Q2</th>
                                        <th>Q3</th>
                                        <th>Q4</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{collega.contactHoursQ1}</td>
                                        <td>{collega.contactHoursQ2}</td>
                                        <td>{collega.contactHoursQ3}</td>
                                        <td>{collega.contactHoursQ4}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Z-Code</th>
                                <th>Naam</th>
                                <th>Opleiding</th>
                                <th>Lesgroepen</th>
                                <th>Contacturen</th>
                                <th className="assigncol">Vakdocenten</th>
                            </tr>
                        </thead>
                        <tbody>
                            {olaAssignments.map(c => (
                                <tr key={c.ID} className={c.show ? "" : "d-none"}>
                                    <td>{c.ola.opo.zCode}</td>
                                    <td>
                                        {c.ola.name}
                                        <br />
                                        <i className="fas fa-arrow-right" />
                                        &nbsp;{c.vte}&nbsp;%
                                        <br />
                                        <br />
                                        {c.opoResponsible && (
                                            <span>
                                                <i className="far fa-award" /> OPO-coördinator
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-center">{c.ola.phases[0].phase.programme.name}</td>
                                    <td className="text-center">{c.ola.internship ? <span>{c.internshipStudents} studenten</span> : <span>{c.numberOfGroups}</span>}</td>
                                    <td>
                                        {!c.ola.internship && (
                                            <Table striped bordered hover className="text-center">
                                                <thead>
                                                    <tr>
                                                        <th>Q1</th>
                                                        <th>Q2</th>
                                                        <th>Q3</th>
                                                        <th>Q4</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{c.contactHoursQ1}</td>
                                                        <td>{c.contactHoursQ2}</td>
                                                        <td>{c.contactHoursQ3}</td>
                                                        <td>{c.contactHoursQ4}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        )}
                                    </td>
                                    <td>
                                        {!c.ola.internship && (
                                            <Row className="mx-2">
                                                {c.ola.assignments.filter(b => b.show === true).map(z =>
                                                    <Col xs={2} className="my-2 px-1">
                                                        <Button variant="outline-dark" className={z.show ? "collegaButton" : "collegaButton d-none"}>
                                                            <div className="collega-name text-truncate">
                                                                {(z.ola.name === "Data Analysis with SQL" && (z.collega.uNumber === "u0068491" || z.collega.uNumber === "u0133198") && (localStorage.getItem("username") === "u0133198" || localStorage.getItem("username") === "u0068491" || localStorage.getItem("username") === "u0068468" || localStorage.getItem("username") === "u0148838")) && ( // Omdat Tinne en Hans volgend jaar mogen koken, want ook dat is belangrijk in SAT!
                                                                    <span>
                                                                        <i className="far fa-hat-chef" />{" "}
                                                                    </span>
                                                                )}
                                                                {z.opoResponsible && (
                                                                    <span>
                                                                        <i className="far fa-award" />{" "}
                                                                    </span>
                                                                )}{" "}

                                                                {localStorage.getItem("SAT-typeName") === "volledig"
                                                                    ? z.collega.firstName
                                                                    : z.collega.initials}
                                                            </div>

                                                            {localStorage.getItem("SAT-typeName") === "volledig" && (
                                                                <div className="collega-name text-truncate">{z.collega.lastName}</div>
                                                            )}

                                                            <div className="fw-bold assignmentVTE">
                                                                {z.numberOfAssignments > 1 ? (
                                                                    <span><span className="font-bold">{z.numberOfAssignments}</span> groepen</span>
                                                                ) : (
                                                                    <span><span className="font-bold">1</span> groep</span>
                                                                )}
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                )}
                                            </Row>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <h3>Deeltaken: {(collega.taskVTE - collega.graduatesForfait).toFixed(2)} %</h3>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Naam</th>
                                <th>Opleiding</th>
                                <th>Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {taskAssignments.map(assignment => (
                                <tr key={assignment.ID} className={assignment.show ? "" : "d-none"}>
                                    <td>{assignment.task.name}</td>
                                    <td>{assignment.programme.name}</td>
                                    <td>{assignment.percentageOverride !== 0 ? <span>{assignment.percentageOverride} %</span> : <span>{assignment.task.percentage} %</span>}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <h3>Forfait: {((Math.round((collega.totalVTE - collega.olaVTE - collega.taskVTE) * 2) / 2) + collega.graduatesForfait).toFixed(2)} %</h3>
                    <h2>Totaal: {(collega.olaVTE + collega.taskVTE + Math.round((collega.totalVTE - collega.olaVTE - collega.taskVTE) * 2) / 2).toFixed(2)} %</h2>
                </Col>
            </Row>
        </Container>
    );
}

export default PerformanceScheme;
