/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Accordion, Container, Col, Button, Table, Popover, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import { filterErrors } from "../../_helpers/calculations";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function RulesCheck() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [programmes, setProgrammes] = useState([]);

  const loadErrors = async () => {
    try {
      setLoading(true);
      const response = await api.checkRules();
      response.sort((a, b) => b.severity > a.severity || b.subjectType > a.subjectType || b.title < a.title);
      const allProgrammes = await api.getProgrammes(snapshot);

      // Only show programmes that the user wants to see
      const filteredProgrammes = JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter'));
      allProgrammes.forEach(programme => {
        programme.show = false;

        if (filteredProgrammes.length === 0) {
          programme.show = true;
        } else {
          filteredProgrammes.forEach(filteredProgramme => {
            if (filteredProgramme.ID === programme.ID) {
              programme.show = true;
            }
          });
        }
      });

      // Check which errors are "my problem" (use filter function)
      setErrors(filterErrors(response));
      setProgrammes(allProgrammes);
      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };

  useEffect(() => {
    loadErrors();
  }, []);

  const popover = () => (
    <Popover>
      <Popover.Header as="h3">Legende</Popover.Header>
      <Popover.Body>
        <h5>Symbolen</h5>
        <p><i className="fad fa-exclamation-circle text-danger" /> Error</p>
        <p><i className="fad fa-exclamation-triangle text-rule-error" /> Waarschuwing</p>
        <p><i className="fas fa-users" /> Overkoepelend probleem (niet opleidingsgebonden)</p>
        <p><i className="fas fa-user" /> Probleem binnen gefilterde opleiding(en)</p>
      </Popover.Body>
    </Popover>
  );

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      <Row>
        <h1>The system formerly known as SAT RULE(s) ENGINE</h1>
      </Row>
      <Row className="my-2">
        <Col>
          <div className="float-end fixedElement">
            <OverlayTrigger trigger="click" placement="left" overlay={popover()}>
              <Button variant="info" className="me-2">
                <i className="fas fa-question" />
              </Button>
            </OverlayTrigger>
          </div>
        </Col>
      </Row>
      <Row>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Opleiding</th>
              <th>Errors</th>
              <th>Waarschuwingen</th>
            </tr>
          </thead>
          <tbody>
            <tr key={0}>
              <td>Geen opleiding</td>
              <td><i className="fad fa-exclamation-circle text-danger" /> {errors.filter(e => e.programmeId === 0 && e.severity >= 10).length}</td>
              <td><i className="fad fa-exclamation-triangle text-rule-error" /> {errors.filter(e => e.programmeId === 0 && e.severity < 10).length}</td>
            </tr>
            {programmes.map(programme => (
              <tr key={programme.ID} className={programme.show ? "" : "d-none"}>
                <td>{programme.name}</td>
                <td><i className="fad fa-exclamation-circle text-danger" /> {errors.filter(e => e.programmeId === programme.ID && e.severity >= 10).length}</td>
                <td><i className="fad fa-exclamation-triangle text-rule-error" /> {errors.filter(e => e.programmeId === programme.ID && e.severity < 10).length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Col>
          <Accordion>
            {errors.map(e => (
              <Accordion.Item eventKey={e.title}>
                <Accordion.Header>
                  {e.severity >= 10 ? <i className="fad fa-exclamation-circle text-danger" /> : <i className="fad fa-exclamation-triangle text-rule-error" />}
                  &nbsp;{e.title}&nbsp;{e.programmeId === 0 && <i className="fas fa-users" />}&nbsp;{(e.programmeId !== 0 && JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter')).length !== 0) && <i className="fas fa-user" />}
                </Accordion.Header>
                <Accordion.Body>
                  <p>{e.description}</p>
                  <p>
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        window.location.href = e.subject;
                      }}>
                      Bekijk onderwerp <i className="fas fa-external-link" />
                    </Button>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default RulesCheck;
