import Kanban from "./Kanban";

function PersonalView() {
    return (
        <div className="App">
            <Kanban />
        </div>
    );
}

export default PersonalView;