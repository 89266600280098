/* eslint-disable import/prefer-default-export */
import sortBy from "lodash.sortby";
import SatAPI from "../_services/sat";

const api = SatAPI();

// copies an object or array to not use the original
const cp = a => {
  // if an array is passed, copy the array
  if (Array.isArray(a)) {
    return a.map(e => cp(e));
  }

  // if an object is passed, copy the object
  if (typeof a === "object") {
    // eslint-disable-next-line prefer-object-spread
    return Object.assign({}, a);
  }

  // if a primitive is passed, return the primitive
  return a;
};

// This is where all the magic math happens!
// Simsalabim!

export const loadParameters = async () => {
  const general = await api.getGeneralParameters();
  const cs = await api.getClassSizesParameters();
  const ects = await api.getECTSLoadsParameters();
  const phases = await api.getPhases();

  return {
    general,
    cs,
    ects,
    phases,
  };
};

export const loadParametersPerformanceScheme = async () => {
  const general = await api.getGeneralParameters();
  const cs = await api.getClassSizesParameters();
  const ects = await api.getECTSLoadsParameters();

  return {
    general,
    cs,
    ects,
  };
};

const getFactorForECTS = (ectsList, ectsCredits) => {
  const item = ectsList.find(ects => ects.min <= ectsCredits && ects.max >= ectsCredits);
  return Math.round(item.hoursPerGrade * 50) / 50;
};

const getFactorForClassSize = (classSizeList, students, classes, first) => {
  if (classes === 0 || students === 0) {
    return 0;
  }

  const classSize = (students / classes).toFixed(0);
  const item = classSizeList.find(cs => cs.min <= classSize && cs.max >= classSize);

  if (item == null) {
    console.log(classes, "klassen, ", students, "studenten -", classSize, "studenten per klas");
    return 0;
  }

  return first ? item.firstLoad : item.parallelLoad;
};

const getPhaseForID = (phases, id) => phases.find(phase => phase.ID === id);

const getStudentsForOLAPhase = OLAPhase => {
  if (Number.isNaN(OLAPhase.surplus)) {
    return OLAPhase.phase.numberOfStudents;
  }
  return OLAPhase.phase.numberOfStudents + OLAPhase.surplus;
};
const getNumberOfClassesForOLAPhase = OLAPhase => OLAPhase.numberOfClassesOverride || OLAPhase.phase.numberOfClasses;

export const generateParallelLists = (parallels) => {
  const parallelList = [];

  let parallelGroup = [];
  let previousMainOLAID = -1;
  parallels.sort((a, b) => b.mainOLA.name < a.mainOLA.name ? 1 : -1);
  parallels.forEach(parallel => {
    if (previousMainOLAID !== parallel.mainOLAID) {
      if (parallelGroup.length !== 0) {
        parallelGroup.sort((a, b) => (b.numberOfStudents > a.numberOfStudents || b.level < a.level) ? 1 : -1);
        parallelList.push(parallelGroup);
      }
      parallelGroup = []

      // add main OLA to parallel group
      let numberOfStudents = 0;
      if (parallel.mainOLA.phases !== null) {
        parallel.mainOLA.phases.forEach(olaPhase => {
          numberOfStudents += getStudentsForOLAPhase(olaPhase);
        });
        parallelGroup.push({ "ola": parallel.mainOLA, "olaID": parallel.mainOLAID, "numberOfStudents": numberOfStudents, level: 0 });
      }
    }

    // add sub OLA to parallel group
    let numberOfStudents = 0;
    if (parallel.subOLA.phases !== null) {
      parallel.subOLA.phases.forEach(olaPhase => {
        numberOfStudents += getStudentsForOLAPhase(olaPhase);
      });
      parallelGroup.push({ "ola": parallel.subOLA, "olaID": parallel.subOLAID, "numberOfStudents": numberOfStudents, level: parallel.level })
    }

    previousMainOLAID = parallel.mainOLAID;
  });

  if (parallelGroup.length !== 0) {
    parallelGroup.sort((a, b) => (b.numberOfStudents > a.numberOfStudents || b.level < a.level) ? 1 : -1);
    parallelList.push(parallelGroup);
  }

  return parallelList;
};

const checkIfAssignmentIsParallel = (assignment, parallels, allAssignments) => {
  const parallelsList = generateParallelLists(parallels); // TO DO: verplaatsen naar hoger gelegen functie om te vermijden dat het te vaak wordt uitgevoerd

  // Check whether the OLA can be found in the parallel list or not.
  let parallelGroup = [];
  parallelsList.forEach(group => {
    group.forEach(olaInfo => {
      if (olaInfo.olaID === assignment.ola.ID) {
        parallelGroup = group;
      }
    });
  });

  // No parallel group means OLA does not have parallel OLAs
  if (parallelGroup.length === 0) {
    return 2;
  }

  // Put all assignments of that collega for all OLAs of the parallel group in a list
  // in the order in which the parallel OLAs are defined in the parallel group
  const assignmentsWithinParallelForCollega = [];

  let assignmentsForOLAAndCollega = [];
  parallelGroup.forEach(olaInfo => {
    assignmentsForOLAAndCollega = allAssignments.filter((currentAssignment) => currentAssignment.olaID === olaInfo.olaID && currentAssignment.collegaID === assignment.collegaID);
    if (assignmentsForOLAAndCollega) {
      assignmentsForOLAAndCollega.forEach(assignmentForOLAAndCollega => {
        assignmentsWithinParallelForCollega.push(assignmentForOLAAndCollega);
      });
    }
  });

  // This collega does not teach any of these OLAs
  if (assignmentsWithinParallelForCollega.length === 0) {
    return 2;
  }

  // If the current assignment is the first in the list, it is not a parallel, otherwise it is
  return (assignmentsWithinParallelForCollega[0].ID !== assignment.ID ? 1 : 0);

};

// calculate how much an OPO costs, based on as many different persons as possible teaching it (a different person for each class)
export const calculateOPOVTEWorstCase = async olas => {
  const { general, cs, ects, phases } = await loadParameters();
  let hours = 0;

  olas.forEach(ola => {
    let olaHours = 0;

    ola.phases.forEach(phase => {
      phase.phase = getPhaseForID(phases, phase.phaseID);
      const factor = getFactorForClassSize(cs, getStudentsForOLAPhase(phase), getNumberOfClassesForOLAPhase(phase), true);
      const hoursPerGrade = getFactorForECTS(ects, ola.ectsCredits);
      const numberOfClasses = getNumberOfClassesForOLAPhase(phase);
      olaHours += Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5 * numberOfClasses; // rounding to 0,5 applied
    });

    hours += olaHours;
  });

  const worstCaseVTE = (hours + general.opoResposibleExtra) / 100;
  // Addition of 1 because the OPO responsible gets 1% extra;
  // division by 100 because we want to see it as VTE, not as percentage
  console.log("Worst case VTE: ", worstCaseVTE);

  return Math.round(worstCaseVTE * 1000) / 1000;
};

// calculate how much an OPO costs, based on 1 person teaching it
export const calculateOPOVTEBestCase = async olas => {
  const { general, cs, ects, phases } = await loadParameters();
  let hours = 0;
  let firstPhasePassed = false;

  olas.forEach(ola => {
    let olaHours = 0;

    ola.phases.forEach(phase => {
      let factor = 0;
      const hoursPerGrade = getFactorForECTS(ects, ola.ectsCredits);
      console.log("Hours per grade:", hoursPerGrade);

      if (!firstPhasePassed) {
        phase.phase = getPhaseForID(phases, phase.phaseID);
        factor = getFactorForClassSize(cs, getStudentsForOLAPhase(phase), getNumberOfClassesForOLAPhase(phase), true);
        console.log("Factor", factor);
        olaHours += Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5; // first class of this phase, rounding to 0,5 applied
        firstPhasePassed = true;
        console.log("OLA hours (first): ", Math.round((factor * hoursPerGrade * ola.ectsCredits) / 0.5) * 0.5);
        console.log("OLA hours (total):", olaHours);
      }

      const numberOfClasses = getNumberOfClassesForOLAPhase(phase);
      if (firstPhasePassed) {
        factor = getFactorForClassSize(cs, getStudentsForOLAPhase(phase), getNumberOfClassesForOLAPhase(phase), false);
        console.log("Factor", factor);
        olaHours += Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5 * (numberOfClasses - 1); // next classes of this phase, rounding to 0,5 applied
        console.log("OLA hours: ", Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5 * (numberOfClasses - 1));
        console.log("OLA hours (total):", olaHours);
      } else {
        factor = getFactorForClassSize(cs, getStudentsForOLAPhase(phase), getNumberOfClassesForOLAPhase(phase), true);
        console.log("Factor", factor);
        olaHours += Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5 * numberOfClasses; // all classes for this phase, rounding to 0,5 applied
        console.log("OLA hours: ", Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5 * numberOfClasses);
        console.log("OLA hours (total):", olaHours);
      }
    });

    hours += olaHours;
  });

  const bestCaseVTE = (hours + general.opoResposibleExtra) / 100;
  // Addition of 1 because the OPO responsible gets 1% extra;
  // division by 100 because we want to see it as VTE, not as percentage
  console.log("Best case VTE: ", bestCaseVTE);

  return Math.round(bestCaseVTE * 1000) / 1000;
};

// calculate the actual cost of an OLA, based on its current assignments
/* export const calculateOLAVTE = async ola => {
  const { general, cs, ects } = await loadParameters();
  const assignments = await api.getAssignments();

  ola.assignments = assignments.filter(a => a.olaID === ola.ID);
  ola.assignments.sort((a, b) => a.collega.lastName > b.collega.lastName);

  const opoRespAssignment = ola.assignments.filter(a => a.opoResponsible);
  if (opoRespAssignment.length > 0) {
    ola.assignments = sortBy(ola.assignments, [
      o => !o.opoResponsible,
      o => o.collegaID !== opoRespAssignment[0].collegaID,
      "collega.lastName",
      "collega.firstName",
    ]);
  } else {
    ola.assignments = sortBy(ola.assignments, ["collega.lastName", "collega.firstName"]);
  }

  let olaHours = 0;
  let numberOfStudents = 0;
  let numberOfClasses = 0;
  let previousCollegaID = 0;
  let OPOresponsibles = 0;
  let percentageOverride = 0;
  ola.assignments.forEach(assignment => {
    ola.phases.forEach(OLAphase => {
      if (OLAphase.surplus !== 0 && OLAphase.surplus !== null) {
        numberOfStudents += OLAphase.surplus;
      }
      numberOfStudents += OLAphase.phase.numberOfStudents; // count normal number of students (always)

      if (OLAphase.numberOfClassesOverride !== 0 && OLAphase.numberOfClassesOverride !== null) {
        numberOfClasses += OLAphase.numberOfClassesOverride;
      } else {
        numberOfClasses += OLAphase.phase.numberOfClasses;
      }
    });

    let factor = 0;
    const hoursPerGrade = getFactorForECTS(ects, ola.ectsCredits);
    factor = getFactorForClassSize(cs, numberOfStudents, numberOfClasses, previousCollegaID !== assignment.collegaID);
    olaHours += factor * hoursPerGrade * ola.ectsCredits;

    if (assignment.opoResponsible) {
      OPOresponsibles += 1;
    }

    previousCollegaID = assignment.collegaID;

    if (assignment.percentageOverride !== 0 && assignment.percentageOverride !== null) {
      percentageOverride += assignment.percentageOverride;
    }
  });

  let VTEforOLA = (olaHours / general.fullTimeHour + 1) / 100;

  VTEforOLA += (general.opoResposibleExtra / 100) * OPOresponsibles;
  VTEforOLA += percentageOverride;

  // return Math.round(VTEforOLA * 1000) / 1000;
  return VTEforOLA;
}; */

// calculate the actual cost of an OLA, based on its current assignments
/* export const calculateOLAVTEPreFetched = (ola, assignments, phases, general, cs, ects) => {
  ola.assignments = assignments.filter(a => a.olaID === ola.ID);
  ola.assignments.sort((a, b) => a.collega.lastName > b.collega.lastName);

  const opoRespAssignment = ola.assignments.filter(a => a.opoResponsible);
  if (opoRespAssignment.length > 0) {
    ola.assignments = sortBy(ola.assignments, [
      o => !o.opoResponsible,
      o => o.collegaID !== opoRespAssignment[0].collegaID,
      "collega.lastName",
      "collega.firstName",
    ]);
  } else {
    ola.assignments = sortBy(ola.assignments, ["collega.lastName", "collega.firstName"]);
  }

  let olaHours = 0;
  let numberOfStudents = 0;
  let numberOfClasses = 0;
  let previousCollegaID = 0;
  let OPOresponsibles = 0;
  let percentageOverride = 0;
  assignments.forEach(assignment => {
    console.log(ola.name, "Assignment:", assignment.ola, assignment.collega.lastName);
    phases.forEach(OLAphase => {
      if (OLAphase.surplus !== 0 && OLAphase.surplus !== null) {
        numberOfStudents += OLAphase.surplus;
      }
      numberOfStudents += OLAphase.phase.numberOfStudents; // count normal number of students (always)

      if (OLAphase.numberOfClassesOverride !== 0 && OLAphase.numberOfClassesOverride !== null) {
        numberOfClasses += OLAphase.numberOfClassesOverride;
      } else {
        numberOfClasses += OLAphase.phase.numberOfClasses;
      }
    });
    console.log(ola.name, "Classes:", numberOfClasses);
    console.log(ola.name, "Students:", numberOfStudents);

    let factor = 0;
    const hoursPerGrade = getFactorForECTS(ects, ola.ectsCredits);
    console.log(ola.name, "Hours per grade:", hoursPerGrade);
    factor = getFactorForClassSize(cs, numberOfStudents, numberOfClasses, previousCollegaID !== assignment.collegaID);
    console.log(ola.name, "factor", factor);
    olaHours += factor * hoursPerGrade * ola.ectsCredits;
    console.log(ola.name, "OLA hours:", olaHours);

    if (assignment.opoResponsible) {
      OPOresponsibles += 1;
    }
    console.log(ola.name, "OPO Responsible:", assignment.opoResponsible);

    previousCollegaID = assignment.collegaID;
    console.log(ola.name, "Wie is het?", assignment.collegaID, assignment.collega.lastName);

    if (assignment.percentageOverride !== 0 && assignment.percentageOverride !== null) {
      percentageOverride += assignment.percentageOverride;
    }
  });

  let VTEforOLA = (olaHours / general.fullTimeHour + 1) / 100;

  VTEforOLA += (general.opoResposibleExtra / 100) * OPOresponsibles;
  VTEforOLA += percentageOverride;
  console.log(ola.name, "VTE for OLA:", VTEforOLA);

  // return Math.round(VTEforOLA * 1000) / 1000;
  return VTEforOLA;
}; */

// calculate the actual cost of an OLA, based on its current assignments (roundings applied)
export const calculateRoundedOLAVTEPreFetched = (olaOrig, assignmentsOrig, phasesOrig, parallelsOrig, allAssignments, general, cs, ects) => {
  const ola = cp(olaOrig);
  const assignments = cp(assignmentsOrig);
  const phases = cp(phasesOrig);

  ola.assignments = assignments.filter(a => a.olaID === ola.ID);
  ola.assignments.sort((a, b) => a.collega.lastName > b.collega.lastName);

  const opoRespAssignment = ola.assignments.filter(a => a.opoResponsible);
  if (opoRespAssignment.length > 0) {
    ola.assignments = sortBy(ola.assignments, [
      o => !o.opoResponsible,
      o => o.collegaID !== opoRespAssignment[0].collegaID,
      "collega.lastName",
      "collega.firstName",
    ]);
  } else {
    ola.assignments = sortBy(ola.assignments, ["collega.lastName", "collega.firstName"]);
  }

  let olaHours = 0;
  let numberOfStudents = 0;
  let numberOfClasses = 0;
  let previousCollegaID = 0;
  let OPOresponsibles = 0;
  let percentageOverride = 0;
  assignments.forEach(assignment => {
    console.log(ola.name, "Assignment:", assignment.ola, assignment.collega.lastName);
    phases.forEach(OLAphase => {
      if (OLAphase.surplus !== 0 && OLAphase.surplus !== null) {
        numberOfStudents += OLAphase.surplus;
      }
      numberOfStudents += OLAphase.phase.numberOfStudents; // count normal number of students (always)

      if (OLAphase.numberOfClassesOverride !== 0 && OLAphase.numberOfClassesOverride !== null) {
        numberOfClasses += OLAphase.numberOfClassesOverride;
      } else {
        numberOfClasses += OLAphase.phase.numberOfClasses;
      }
    });
    // console.log(ola.name, "Classes:", numberOfClasses);
    // console.log(ola.name, "Students:", numberOfStudents);

    let factor = 0;
    const hoursPerGrade = getFactorForECTS(ects, ola.ectsCredits);
    // console.log(ola.name, "Hours per grade:", hoursPerGrade);

    let isParallel = false;

    switch (checkIfAssignmentIsParallel(assignment, parallelsOrig, allAssignments)) {
      case 0: // parallel OLAs known, but no parallel
        isParallel = false;
        break;
      case 1: // parallel OLAs known, it is a parallel
        isParallel = true;
        break;
      case 2: // no parallel OLAs known, use logic for parallel within OLA
        isParallel = previousCollegaID === assignment.collegaID;
        break;
      default:
        isParallel = false;
    }

    factor = getFactorForClassSize(cs, numberOfStudents, numberOfClasses, !isParallel);
    console.log(ola.name, "factor", factor);
    olaHours += Math.round((factor * hoursPerGrade * ola.ectsCredits) / general.fullTimeHour / 0.5) * 0.5; // rounding to 0,5 applied
    console.log(ola.name, "OLA hours:", olaHours);

    if (assignment.opoResponsible) {
      OPOresponsibles += 1;
    }
    console.log(ola.name, "OPO Responsible:", assignment.opoResponsible);

    previousCollegaID = assignment.collegaID;
    console.log(ola.name, "Wie is het?", assignment.collegaID, assignment.collega.lastName);
    if (assignment.percentageOverride !== 0 && assignment.percentageOverride !== null) {
      percentageOverride += assignment.percentageOverride / 100;
    }
  });

  let VTEforOLA = (olaHours + general.opoResposibleExtra * OPOresponsibles) / 100;
  console.log(ola.name, "VTE for OLA:", VTEforOLA);
  VTEforOLA += percentageOverride;

  // return Math.round(VTEforOLA * 1000) / 1000;
  return VTEforOLA;
};

export const getContactHours = (collega, assignments, quarter) => {
  // quarter:
  // 0 = All year
  // 1 = Q1
  // 2 = Q2
  // 3 = Q3
  // 4 = Q4
  // 5 = Semester 1
  // 6 = Semester 2

  console.log("Meegegeven collega: ", collega);
  console.log("Meegegeven assignments: ", assignments);
  console.log("Gekozen kwartaal: ", quarter);

  const filteredAssignments = assignments.filter(a => a.collegaID === collega.ID);
  let hours = 0;

  console.log("Filtered assignments: ", filteredAssignments);

  filteredAssignments.forEach(assignment => {
    switch (quarter) {
      case 1:
        hours += assignment.ola.contactQ1;
        break;
      case 2:
        hours += assignment.ola.contactQ2;
        break;
      case 3:
        hours += assignment.ola.contactQ3;
        break;
      case 4:
        hours += assignment.ola.contactQ4;
        break;
      case 5: // Semester 1
        hours += assignment.ola.contactQ1 + assignment.ola.contactQ2;
        break;
      case 6: // Semester 2
        hours += assignment.ola.contactQ3 + assignment.ola.contactQ4;
        break;
      default: // All year
        hours += assignment.ola.contactQ1 + assignment.ola.contactQ2 + assignment.ola.contactQ3 + assignment.ola.contactQ4;
    }
  });

  return hours;
};

// calculate the actual cost of a collega, based on its current assignments (roundings applied)
export const calculateRoundedCollegaVTEPreFetched = (collegaOrig, assignmentsOrig, taskAssignmentsOrig, parallelsOrig, allAssignmentsOrig, general, cs, ects, includeForfait = true) => {
  const collega = cp(collegaOrig);
  const assignments = cp(assignmentsOrig);
  const taskAssignments = cp(taskAssignmentsOrig);

  collega.assignments = assignments.filter(a => a.collegaID === collega.ID);
  collega.assignments.sort(a => a.ola.ID);

  collega.taskAssignments = taskAssignments.filter(a => a.collegaID === collega.ID);

  let VTEforOLAAssignments = 0;
  let VTEforTaskAssignments = 0;
  let forfaitHours = 0;
  let previousOLAID = 0;
  let loopedOLAs = [];
  collega.assignments.forEach(assignment => {
    if (!assignment.ola.phases || assignment.ola.phases.length === 0) {
      // invalid OLA
      return;
    }
    if (assignment.ola.internship) {
      console.log("Stage Assignment:", assignment);
      const OLAAssignmentVTE = (assignment.internshipStudents * assignment.ola.internshipPercentage) / 100;
      VTEforOLAAssignments += OLAAssignmentVTE;
      console.log("Stage VTE:", OLAAssignmentVTE);

      // forfait
      if (!collega.noForfait && assignment.ola.phases[0].phase.programme.forfaitFactor > 0) {
        forfaitHours += OLAAssignmentVTE * assignment.ola.phases[0].phase.programme.forfaitFactor;
      }
    } else if (loopedOLAs.indexOf(assignment.ola.ID) < 0) {
      const collegaAssignmentsPerOLA = collega.assignments.filter(b => b.olaID === assignment.olaID);
      const OLAAssignmentVTE = calculateRoundedOLAVTEPreFetched(assignment.ola, collegaAssignmentsPerOLA, assignment.ola.phases, parallelsOrig, allAssignmentsOrig, general, cs, ects);
      VTEforOLAAssignments += OLAAssignmentVTE;

      // forfait
      if (!collega.noForfait && assignment.ola.phases[0].phase.programme.forfaitFactor > 0) {
        forfaitHours += OLAAssignmentVTE * assignment.ola.phases[0].phase.programme.forfaitFactor;
      }
    }
    previousOLAID = assignment.ola.ID;
    loopedOLAs = [...loopedOLAs, previousOLAID];
  });

  collega.taskAssignments.forEach(taskAssignment => {
    if (taskAssignment.percentageOverride === 0) {
      VTEforTaskAssignments += taskAssignment.task.percentage / 100;
      console.log("Task assignment", taskAssignment.task.name, "for", collega.firstName, collega.lastName, ":", taskAssignment.task.percentage);

      // forfait
      if (!collega.noForfait && taskAssignment.task.hasForfait && taskAssignment.programme.forfaitFactor > 0) {
        forfaitHours += taskAssignment.task.percentage * (100 / taskAssignment.programme.forfaitFactor);
      }
    } else {
      VTEforTaskAssignments += taskAssignment.percentageOverride / 100;
      console.log(
        "Task assignment",
        taskAssignment.task.name,
        "for",
        collega.firstName,
        collega.lastName,
        ":",
        taskAssignment.percentageOverride,
        "(override)",
      );

      // forfait
      if (!collega.noForfait && taskAssignment.task.hasForfait && taskAssignment.programme.forfaitFactor > 0) {
        forfaitHours += taskAssignment.percentageOverride * (100 / taskAssignment.programme.forfaitFactor);
      }
    }
  });

  forfaitHours = Math.round(forfaitHours * 2) / 2;
  const forfaitPercentage = forfaitHours / general.fullTimeHour / 100;

  console.log("VTE for collega:", VTEforOLAAssignments + VTEforTaskAssignments);
  console.log("Forfait for collega:", forfaitHours, "hours =", forfaitPercentage, "VTE");

  let fullVTE = 0;
  if (includeForfait) {
    fullVTE = Math.round((VTEforOLAAssignments + VTEforTaskAssignments + forfaitPercentage) * 100 * 1000) / 1000; // return in %
  } else {
    fullVTE = Math.round((VTEforOLAAssignments + VTEforTaskAssignments) * 100 * 1000) / 1000; // return in %
  }
  // return Math.round(fullVTE * 2) / 2; // round to 0.5
  return fullVTE;
};

// calculate the actual cost of a programme, based on its current assignments (roundings applied)
export const calculateRoundedProgrammeVTEPreFetched = (collegas, assignments, taskAssignments, programme, parallels, general, cs, ects) => {
  const allAssignments = cp(assignments);
  const programmeAssignments = assignments.filter(a => {
    if (!a.ola.phases || a.ola.phases.length === 0) {
      return false;
    }
    return a.ola.phases[0].phase.programme.ID === programme.ID;
  });
  const programmeTaskAssignments = taskAssignments.filter(a => a.programmeID === programme.ID);

  let programmeVTE = 0;
  collegas.forEach(collega => {
    programmeVTE += calculateRoundedCollegaVTEPreFetched(collega, programmeAssignments, programmeTaskAssignments, parallels, allAssignments, general, cs, ects);
  });

  return programmeVTE / 100; // return as VTE instead of percentage
};

export const getFilteredAvailability = (collega, programmes) => {
  let percentage = 0;

  collega.programmes.forEach(collegaProgramme => {
    programmes.forEach(programme => {
      if (collegaProgramme.programmeID === programme.ID) {
        percentage += collegaProgramme.percentage;
      }
    });
  });

  return percentage;
};

export const getVTEForCollegaByProgrammes = (collega, assignments, taskAssignments, parallels, allAssignments, general, cs, ects, programmes) => {
  let collegaVTE = 0;

  programmes.forEach(programme => {
    const programmeAssignments = assignments.filter(a => {
      if (!a.ola.phases || a.ola.phases.length === 0) {
        return false;
      }
      return a.ola.phases[0].phase.programme.ID === programme.ID;
    });
    const programmeTaskAssignments = taskAssignments.filter(a => a.programmeID === programme.ID);

    collegaVTE += calculateRoundedCollegaVTEPreFetched(collega, programmeAssignments, programmeTaskAssignments, parallels, allAssignments, general, cs, ects);
  });

  return collegaVTE;
};

export const filterErrors = (errors) => {
  const filteredErrors = [];

  const filteredProgrammes = JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter'));

  // Check which errors are "my problem"
  errors.forEach(error => {
    if (filteredProgrammes.length !== 0) {
      filteredProgrammes.forEach(filteredProgramme => {
        if ((error.programmeId === filteredProgramme.ID || error.programmeId === 0) && filteredErrors.indexOf(error) === -1) {
          filteredErrors.push(error);
        }
      });
    } else {
      filteredErrors.push(error);
    }
  });

  return filteredErrors;
};

// calculate the availability of employees for the given programme
export const calculateAvailableFTEPreFetched = (collegasOrig, programmeOrig) => {
  let availableFTE = 0;
  collegasOrig.forEach(collega => {
    collega.programmes.forEach(collegaProgramme => {
      if (collegaProgramme.programmeID === programmeOrig.ID) {
        availableFTE += collegaProgramme.percentage;
      }
    });
  });

  return availableFTE / 100;
};