import { round } from 'lodash';
import SatAPI from "../_services/sat";
import { importFromExcel } from "./excel";

const api = SatAPI();

const excelRows = {
    programme: 1,
    olaTask: 2,
    phase: 3,
    uNumber: 8,
    responsible: 9,
    percentage: 19
};

// eslint-disable-next-line import/prefer-default-export
export const deleteAllAssignmentsForGraduates = async () => {
    const programmes = await api.getProgrammes();
    const tasks = await api.getTasks();
    const taskAssignments = await api.getTaskAssignments();
    const assignments = await api.getAssignments();
    const opos = await api.getOPOs();

    programmes.forEach(programme => {
        if (programme.programmeType === 1) { // 1 = graduates
            // filter out OPOs for this programme
            const tempOPOs = opos.filter(o => o.programmeID === programme.ID);
            tempOPOs.forEach(opo => {
                opo.olas.forEach(ola => {
                    // filter out assignments for this OLA
                    const tempAssignments = assignments.filter(s => s.olaID === ola.ID);
                    // delete all these assignments
                    tempAssignments.forEach(assignment => {
                        api.deleteAssignment(assignment.ID);
                    });
                });
            });

            tasks.forEach(task => {
                task.programmes.forEach(taskProgramme => {
                    if (taskProgramme.ID === programme.ID) {
                        const tempTaskAssignments = taskAssignments.filter(ta => ta.taskID === task.ID && ta.programmeID === programme.ID);
                        tempTaskAssignments.forEach(taskAssignment => {
                            api.deleteTaskAssignment(taskAssignment.ID);
                        });
                    }
                });
            });
        }
    });
};

export const calculateGraduatesForfait = (collega, taskAssignments) => {
    let graduatesForfait = 0.0;

    taskAssignments.forEach(taskAssignment => {
        if (taskAssignment.task.name.toLowerCase() === "forfait") {
            graduatesForfait += taskAssignment.percentageOverride;
        }
    });

    return graduatesForfait;
};

export const importGraduatesFromExcel = async (binairyFile) => {
    const dataRows = importFromExcel(binairyFile);
    dataRows.shift(); // Remove header row
    console.log("Rows in importGraduatesFromExcel", dataRows);
    const programmes = await api.getProgrammes();
    const phases = await api.getPhases();
    const tasks = await api.getTasks();
    const opos = await api.getOPOs();
    const collegas = await api.getCollegas();

    const taskAss = [];
    const courseAss = [];
    const errors = [];
    const warnings = [];

    dataRows.forEach((row, index) => {
        // Skip if percentage is 0
        if (row[excelRows.percentage] === 0) {
            warnings.push(`Waarschuwing op lijn ${index + 2}: Percentage is 0.`);
            return;
        }

        // Check programme
        if (row[excelRows.programme] === undefined) {
            errors.push(`Fout op lijn ${index + 2}: Opleiding niet gevonden.`);
            return;
        }

        // Skip if programme is "Unit"
        if (row[excelRows.programme].toLowerCase() === "unit") {
            warnings.push(`Waarschuwing op lijn ${index + 2}: Opleiding "Unit".`);
            return;
        }

        // Check U-number
        if (!collegas.find(x => x.uNumber === row[excelRows.uNumber])) {
            errors.push(`Fout op lijn ${index + 2}: U-nummer niet gevonden.`);
            return;
        }


        if (!row[excelRows.phase]) {
            // Phase is empty => Task

            // Get programme based on name
            const programme = programmes.find(x => x.name.toLowerCase() === row[excelRows.programme].toLowerCase());
            
            // Check if programme exists
            if (!programme) {
                errors.push(`Fout op lijn ${index + 2}: Opleiding niet gevonden.`);
                return;
            }

            // Create task object if it doesn't exist yet
            const task = row[excelRows.olaTask];
            const taskAssObj = taskAss.find(x => x.task === task);
            const taskAssIndex = taskAss.indexOf(taskAssObj);
            let taskObj = {};
            if (taskAssIndex === -1) {
                taskObj = { task, programmes: [programme], assignments: [] };
            } else {
                taskObj = taskAss[taskAssIndex];
                if (taskObj.programmes.indexOf(programme) === -1) {
                    taskObj.programmes.push(programme);
                }
            }

            // Push assignment to task object
            taskObj.assignments.push({
                unumber: row[excelRows.uNumber],
                percentage: row[excelRows.percentage] * 100,
                programme,
            });

            // Push task object to taskAss array or replace existing task object
            if (taskAssIndex === -1) {
                taskAss.push(taskObj);
            } else {
                taskAss[taskAssIndex] = taskObj;
            }

        } else {
            // Phase not empty => Course

            // Get programme based on name
            const programme = programmes.find(x => x.name === row[excelRows.programme])
            if (!programme) {
                errors.push(`Fout op lijn ${index + 2}: Opleiding niet gevonden.`);
                return;
            }

            // Get phase based on name and programme
            const phase = phases.find(x => x.name === row[excelRows.phase] && x.programme.name === programme.name);
            if (!phase) {
                errors.push(`Fout op lijn ${index + 2}: Fase niet gevonden.`);
                return;
            }

            // Get OPO based on name and phase
            const opo = opos.find(
                x => x.name.toLowerCase() === row[excelRows.olaTask].toLowerCase() &&
                    x.olas.find(
                        y =>
                            y.phases.find(z => z.phase.name === phase.name) &&
                            y.name.toLowerCase() === row[excelRows.olaTask].toLowerCase()
                    )
            );
            if (!opo) {
                errors.push(`Fout op lijn ${index + 2}: OPO of OLA niet gevonden.`);
                return;
            }

            // Get OLA based on name and phase
            const ola = opo.olas.find(
                x => x.name.toLowerCase() === row[excelRows.olaTask].toLowerCase() &&
                    x.phases.find(y => y.phase.name.toLowerCase() === phase.name.toLowerCase() && y.phase.programmeID === programme.ID));

            // push course assignment to courseAss array
            courseAss.push({
                unumber: row[excelRows.uNumber],
                percentage: round(row[excelRows.percentage] * 100, 2),
                ola,
                opoResponsible: (row[excelRows.responsible] !== ''),
            });
        }
    });


    console.log("Tasks: ", taskAss);
    console.log("Courses: ", courseAss);
    console.log("Errors: ", errors);
    console.log("Warnings: ", warnings);

    // Check if there are any errors and return them if there are
    if (errors.length > 0) {
        return { errors, warnings };
    }


    // Create or update tasks and task assignments in database
    let successFullTaskAssignments = 0;
    await Promise.all(taskAss.map(async (ta) => {
        let task = tasks.find(x => x.name === ta.task);

        // Create task if it doesn't exist yet
        if (!task) {
            const taskID = await api.createTask({ name: ta.task, programmes: ta.programmes, hasForfait: false, percentage: 0 });
            task = await api.getTask(taskID.message);
        } else {
            // check if all programmes are already in the task object
            const programmesToAdd = ta.programmes.filter(x => task.programmes.indexOf(x) === -1);
            programmesToAdd.forEach(async (programme) => {
                task.programmes.push(programme);
                await api.updateTask(task.ID, task);
            });
        }

        // Create task assignments
        await ta.assignments.forEach(async (assignm) => {
            await api.createTaskAssignment(
                {
                    taskID: task.ID,
                    programmeID: assignm.programme.ID,
                    collegaID: collegas.find(x => x.uNumber === assignm.unumber).ID,
                    percentageOverride: round(assignm.percentage, 2),
                }
            );
            successFullTaskAssignments += 1;
        });
    }));

    let successFullCourseAssignments = 0;
    await Promise.all(courseAss.map(async (ca) => {
        let { percentage } = ca;

        // Check if course assignment is OPO Responsible
        if (ca.opoResponsible) {
            percentage = ca.percentage - 1;
        }

        // Create course assignment
        await api.createAssignment(
            {
                collegaID: collegas.find(x => x.uNumber === ca.unumber).ID,
                olaID: ca.ola.ID,
                percentageOverride: round(percentage, 2),
                opoResponsible: ca.opoResponsible,
            }
        );
        successFullCourseAssignments += 1;
    }));

    return {
        success: `Successvol afgerond: ${successFullTaskAssignments} taaktoewijzingen en ${successFullCourseAssignments} vaktoewijzingen geïmporteerd.`,
        errors,
        warnings,
    };
}